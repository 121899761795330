import { onBeforeUnmount, ref } from '@vue/composition-api'
import * as realtime from '@/infrastructure/realtime/index'

export default function useRealtimeSubscriptions() {
  const subscriptions = ref([])

  const subscribeToRealtimeEvent = (eventKey, handler) => {
    subscriptions.value = [...subscriptions.value, { key: eventKey, handler: handler }]
    realtime.subscribeToServerEvent(eventKey, handler)
  }

  const unsubscribeAll = () => {
    subscriptions.value.forEach((x) => {
      realtime.unsubscribeFromServerEvent(x.key, x.handler)
    })
  }

  onBeforeUnmount(unsubscribeAll)

  return {
    subscribeToRealtimeEvent,
  }
}
