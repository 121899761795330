<script>
import UserHome from '../components/UserHome'

export default {
  name: 'Home',

  components: {
    UserHome,
  },
}
</script>

<template>
  <div>
    <UserHome row-css-class="mb-3" />
  </div>
</template>
