import Vue from 'vue'

import { HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr'

import config from '../../config'

import store from '@/infrastructure/store'

export const state = Vue.observable({
  connection: undefined,
  isConnected: false,
  isInStartupGracePeriod: true,
  connectAttempts: 0,
  connectFailedAttempts: 0,
  connectionError: undefined,
  queuedServerInvokes: [], // if trying to invokeOnServer without active connection
  latestReceivedEventTimeMs: new Date().getTime(),
})

const reconnectInterval = 5 * 1000
const reconnectUnauthorizedInterval = 90 * 1000
const maximumReconnectInterval = 15 * 60 * 1000

const maxQueuedServerInvokes = 100

const localEventHub = new Vue()

// noinspection JSUnusedGlobalSymbols
const retryPolicy = {
  nextRetryDelayInMilliseconds(retryContext) {
    const retryDelay = Math.min((retryContext.previousRetryCount + 1) * reconnectInterval, maximumReconnectInterval)
    console.log(`Will retry connection in ${retryDelay} ms, retryContext`, retryContext)
    return retryDelay
  },
}

const connection = new HubConnectionBuilder()
  .withUrl(`${config.RealtimeHubUrl}`, {
    accessTokenFactory: () => store.state.auth.authToken || '',
  })
  .withAutomaticReconnect(retryPolicy)
  .configureLogging(LogLevel.Warning) // LogLevel.Information)
  .build()

connection.onreconnecting((err) => {
  console.warn(`SignalR reconnecting at ${new Date().toISOString()}, error: `, err)

  if (state.isConnected) {
    // console.debug('.catch isConnected = false')
    Vue.set(state, 'isConnected', false)
  }
  state.connectAttempts++
  state.connectFailedAttempts++
  state.connectionError = err
})

connection.onreconnected((newConnectionId) => {
  console.log(`SignalR reconnected at ${new Date().toISOString()}, new connectionId: ${newConnectionId}`)

  Vue.set(state, 'isConnected', true)
  state.connectAttempts++
  state.connectFailedAttempts = 0
  state.connectionError = undefined

  sendPendingServerInvokes()
})

connection.onclose(() => {
  // console.debug('.onclose isConnected = false')
  Vue.set(state, 'isConnected', false)
  state.connectionError = Error('Connection closed')
  console.error('SignalR connection closed')
  // start(connection)
})

state.connection = connection

function queuedServerInvoke({ methodName, args }) {
  if (!methodName) {
    console.error('queuedServerInvoke requires a methodName')
    return
  }

  state.queuedServerInvokes.push({ methodName, args })
  while (state.queuedServerInvokes.length > maxQueuedServerInvokes) {
    console.warn(
      `The number of queuedServerInvokes (${state.queuedServerInvokes.length}) is more than max (${maxQueuedServerInvokes}), removing the excess from start of queue`
    )
    state.queuedServerInvokes.shift()
  }
}

function sendPendingServerInvokes() {
  // console.debug('queuedServerInvokes count', state.queuedServerInvokes.length)
  if (state.queuedServerInvokes.length === 0) return
  const invokes = state.queuedServerInvokes.splice(0, state.queuedServerInvokes.length)
  invokes.forEach(({ methodName, args }) => {
    invokeOnServer(methodName, args)
  })
}

async function start() {
  // console.debug('before connection.start()', connection);

  try {
    await connection.start()

    console.assert(connection.state === HubConnectionState.Connected)

    Vue.set(state, 'isConnected', true)
    state.connectAttempts++
    state.connectFailedAttempts = 0
    state.connectionError = undefined

    sendPendingServerInvokes()
  } catch (err) {
    console.assert(connection.state === HubConnectionState.Disconnected)

    if (state.isConnected) {
      // console.debug('.catch isConnected = false')
      Vue.set(state, 'isConnected', false)
      console.error(`Failed to connect with hub at ${new Date().toISOString()}`, err)
    }
    state.connectAttempts++
    state.connectFailedAttempts++
    state.connectionError = err

    const isUnauthorized = String(err?.statusCode) === '401'

    let interval = state.connectFailedAttempts * (isUnauthorized ? reconnectUnauthorizedInterval : reconnectInterval)
    if (interval > maximumReconnectInterval) {
      interval = maximumReconnectInterval
    }
    console.info('Will retry initial SignalR connection after interval', interval)

    setTimeout(() => start(), interval)
  }
}

export async function createRealtimeConnection() {
  state.isInStartupGracePeriod = true
  setTimeout(() => {
    state.isInStartupGracePeriod = false
  }, 5000)

  start()
}

export async function stopRealtimeConnection() {
  if (state.connection && state.connection.stop) {
    try {
      await state.connection.stop()
    } catch (err) {
      console.error('unable to stop connection', err)
    }
    // console.debug('stop() isConnected = false')
    Vue.set(state, 'isConnected', false)
  }
}

function attachToConnectionEvent(eventName) {
  // console.debug('attaching realtime event', eventName)
  state.connection.on(eventName, (...args) => {
    localEventHub.$emit(eventName, ...args)
  })
}

export function subscribeToServerEvent(eventName, callback) {
  attachToConnectionEvent(eventName)

  localEventHub.$on(eventName, callback)
}

export function unsubscribeFromServerEvent(eventName, callback) {
  localEventHub.$off(eventName, callback)
}

/**
 * @param {string} methodName
 * @param {Array} args
 */
export async function invokeOnServer(methodName, args) {
  // console.debug('invokeOnServer', { methodName, args })

  if (!state.isConnected) {
    queuedServerInvoke({ methodName, args })
    return
  }

  try {
    return await state.connection?.invoke(methodName, ...args)
  } catch (err) {
    console.error('Failed to invokeOnServer', { err })
  }
}
