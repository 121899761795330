<script>
import store from '@/infrastructure/store'
import GlobalToastSnackbar from './GlobalToastSnackbar'

export default {
  components: {
    GlobalToastSnackbar,
  },

  computed: {
    toasts: () => store.state.toasts,
  },

  methods: {
    removeToast(toast) {
      store.commit('removeToast', toast)
    },
  },
}
</script>

<template>
  <div>
    <GlobalToastSnackbar
      v-for="toast in toasts"
      :key="toast.id"
      :message="toast.message"
      :color="toast.color"
      :timeout="toast.timeout"
      :action-text="toast.actionText"
      :action-function="toast.actionFunction"
      @close="removeToast(toast)"
    />
  </div>
</template>
