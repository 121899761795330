<script>
import NotificationCard from '@/features/notifications/components/NotificationCard'

export default {
  components: {
    NotificationCard,
  },

  props: {
    notifications: { type: Array, required: true },
    itemCssClass: { type: String },
  },
}
</script>

<template>
  <div>
    <NotificationCard
      v-for="notification in notifications"
      :key="notification.notificationId"
      :notification-id="notification.notificationId"
      :type="notification.type"
      :type-id="notification.typeId"
      :recipient-dependent-id="notification.recipientDependentId"
      :recipient-dependent-name="notification.recipientDependentName"
      :default-title-template="notification.defaultTitleTemplate"
      :default-body-template="notification.defaultBodyTemplate"
      :template-values="notification.templateValues"
      :extra-data="notification.extraData"
      :created="notification.created"
      :read-on="notification.readOn"
      :class="itemCssClass"
    />
  </div>
</template>
