<script>
import config from './config'
import store from '@/infrastructure/store'
import * as realtime from '@/infrastructure/realtime'

import UpdateManager from '@/infrastructure/components/UpdateManager'
import DefaultPageLayout from '@/infrastructure/layouts/DefaultPageLayout'
import AppNav from '@/infrastructure/router/components/AppNav'
import OfflineWarningAlert from '@/infrastructure/components/OfflineWarningAlert.vue'
import WelcomeLoginCard from '@/infrastructure/auth/views/WelcomeLoginView'
import ApplicationBusyLoadingCard from '@/infrastructure/auth/views/ApplicationBusyLoadingCard'
import GlobalErrorsContainer from '@/infrastructure/errors/components/GlobalErrorsContainer'
import GlobalToastsContainer from '@/infrastructure/toasts/components/GlobalToastsContainer'
import UserImpersonationBanner from '@/features/users/components/UserImpersonationBanner'
import UnmetAuthRequirement from '@/infrastructure/auth/views/UnmetAuthRequirement'

export default {
  name: 'App',

  components: {
    OfflineWarningAlert,
    UpdateManager,
    DefaultPageLayout,
    AppNav,
    WelcomeLoginCard,
    ApplicationBusyLoadingCard,
    GlobalErrorsContainer,
    GlobalToastsContainer,
    UserImpersonationBanner,
    UnmetAuthRequirement,
  },

  data() {
    return {
      logo: config.Branding.Logo40,
      appName: config.AppName,
    }
  },

  computed: {
    pageLayoutProps() {
      return this.$route && this.$route.meta && this.$route.meta.pageLayoutProps ? this.$route.meta.pageLayoutProps : {}
    },

    allowAnonymous() {
      return this.$route && this.$route.meta && this.$route.meta.allowAnonymous
    },

    authIsAuthenticated: () => store.state.auth.authIsAuthenticated,
    hasOfficialUserId: () => store.state.auth.hasOfficialUserId,
    authUser: () => store.state.auth.authUser,
    authUserId: () => store.getters['auth/authUserId'],
    authBusyLoading: () => store.state.auth.authBusyLoading,
    authTakingLong: () => store.state.auth.authTakingLong,
    authFullError: () => store.state.auth.authFullError,
    authHasError: () => store.getters['auth/authHasError'],

    realtimeIsConnected: () => realtime.state.isConnected,
    realtimeIsInStartupGracePeriod: () => realtime.state.isInStartupGracePeriod,

    userToImpersonate: () => store.state.userToImpersonate,
    routerViewKey() {
      // used to reload content automatically
      // this.$route.path is needed to ensure realtime updates continue working in FriendWithItems after navigating to FriendWithArchivedItemsView and back
      return `${this.$route.path}-` + (this.userToImpersonate ? this.userToImpersonate.userId : 'no-impersonation')
    },

    unmetAuthRequirement() {
      if (this.authUser && !this.authUser.email_verified) return 'Your email is not verified yet, please verify it first'
      return undefined
    },
  },

  watch: {
    hasOfficialUserId: {
      async handler(to) {
        await realtime.stopRealtimeConnection()
        if (to) {
          // console.debug('User logged in with official id, starting realtime connection')
          await realtime.createRealtimeConnection()
        } else {
          // console.debug('User NOT logged in, stopped polling')
        }
      },
      immediate: true,
    },
  },

  methods: {
    stopImpersonation() {
      store.commit('removeUserToImpersonate')
    },
  },
}
</script>

<template>
  <v-app>
    <DefaultPageLayout v-bind="pageLayoutProps">
      <template v-slot:app-nav>
        <AppNav
          :is-authenticated="authIsAuthenticated"
          :auth-busy-loading="authBusyLoading"
          :auth-has-error="authHasError"
          :auth-user-id="authUserId"
          :auth-user="authUser"
          :realtime-is-connected="realtimeIsConnected"
          :realtime-is-in-startup-grace-period="realtimeIsInStartupGracePeriod"
        />
      </template>

      <template v-slot:top-banner>
        <v-expand-transition>
          <div v-if="userToImpersonate">
            <UserImpersonationBanner :other-user-name="userToImpersonate.fullName" class="mx-3 mt-3" @stop-impersonation="stopImpersonation" />
          </div>
        </v-expand-transition>

        <div v-if="!authBusyLoading && !authIsAuthenticated && !hasOfficialUserId && !allowAnonymous">
          <WelcomeLoginCard />
        </div>

        <v-container v-else-if="authBusyLoading || (authIsAuthenticated && !hasOfficialUserId)">
          <v-row justify="center">
            <v-col cols="12" xl="5" lg="7" md="8" sm="9">
              <ApplicationBusyLoadingCard :auth-busy-loading="authBusyLoading" :auth-taking-long="authTakingLong" :auth-full-error="authFullError" />
            </v-col>
          </v-row>
        </v-container>

        <UnmetAuthRequirement v-if="!authBusyLoading && unmetAuthRequirement" :unmet-requirement="unmetAuthRequirement" />
      </template>

      <template v-slot:content>
        <OfflineWarningAlert />

        <transition v-if="!authBusyLoading && (hasOfficialUserId || allowAnonymous) && !unmetAuthRequirement" name="main-route-transition" mode="out-in">
          <router-view :key="routerViewKey" />
        </transition>

        <GlobalErrorsContainer />
        <GlobalToastsContainer />
        <UpdateManager />
      </template>
    </DefaultPageLayout>
  </v-app>
</template>

<!--suppress CssUnusedSymbol -->
<style scoped>
.main-route-transition-enter-active,
.main-route-transition-leave-to {
  transition: all 0.15s ease;
}

.main-route-transition-enter,
.main-route-transition-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
