import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      dark: {
        primary: '#1976d2',
        secondary: '#424242',
        accent: '#424242',
        error: '#ff5252',
        warning: '#fb8c00',
        success: '#4caf50',
        info: '#2196f3',
      },
      light: {
        primary: '#1976d2',
        secondary: '#424242',
        accent: '#424242',
        error: '#ff5252',
        warning: '#fb8c00',
        success: '#4caf50',
        info: '#2196f3',
      },
    },
    iconfont: 'mdi',
  },
})
