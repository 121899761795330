import axios from 'axios'
import store from '@/infrastructure/store'
import config from '@/config'

export function createApiInstance({ userToImpersonateId, dontAutoAddErrorToStore } = {}) {
  const headers = {
    Authorization: `Bearer ${store.state.auth.authToken}`,
  }

  if (userToImpersonateId) {
    headers['x-impersonate-user-id'] = userToImpersonateId
  } else if (store.state.userToImpersonate) {
    headers['x-impersonate-user-id'] = store.state.userToImpersonate.userId
  }

  const instance = axios.create({
    baseURL: config.ApiBaseUrl,
    timeout: config.ApiDefaultTimeoutSeconds * 1000,
    headers: headers,
  })

  instance.interceptors.request.use((cfg) => {
    if (!config.IsProductionEnvironment) {
      return new Promise((resolve) => setTimeout(() => resolve(cfg), 500))
    }
    return cfg
  })

  instance.interceptors.response.use(
    function (response) {
      // statuses inside range of 2xx
      return response
    },
    function (error) {
      // statuses outside range of 2xx
      // console.debug('axios onRejected', { error })
      if (!dontAutoAddErrorToStore) {
        store.commit('addError', error)
      }
      return Promise.reject(error)
    }
  )

  return instance
}
