<script>
import NotificationsTopnavPopupPanel from '@/features/notifications/components/NotificationsTopnavPopupPanel'

export default {
  components: { NotificationsTopnavPopupPanel },
  props: {
    appName: { type: String, required: true },

    isAuthenticated: { type: Boolean, required: true },
    authBusyLoading: { type: Boolean, required: true },
    authHasError: { type: Boolean, required: true },

    unreadNotificationCount: { type: Number, required: true },

    realtimeIsConnected: { type: Boolean, required: true },
    realtimeIsInStartupGracePeriod: { type: Boolean, required: true },

    routes: { type: Array, required: true },
    logoUrl: { type: String, required: true },
    profilePicUrl: { type: String },
  },

  computed: {
    hasAnyNotifications() {
      return this.unreadNotificationCount > 0
    },
    shortenedUnreadNotificationCount() {
      return this.unreadNotificationCount > 99 ? '99+' : this.unreadNotificationCount
    },
    showRealtimeDisconnectedIndicator() {
      return !this.realtimeIsConnected && !this.realtimeIsInStartupGracePeriod
    },
  },
}
</script>

<template>
  <v-app-bar :clipped-right="$vuetify.breakpoint.lgAndUp" app flat color="primary" class="white--text">
    <img :src="logoUrl" alt="App logo" height="48px" class="mr-3" />
    <router-link to="/" custom v-slot="{ navigate }" class="v-toolbar__title" style="cursor: pointer">
      <div @click="navigate">{{ appName }}</div>
    </router-link>

    <v-spacer></v-spacer>

    <div v-if="isAuthenticated">
      <NotificationsTopnavPopupPanel v-if="$vuetify.breakpoint.mdAndUp">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-badge color="error" :content="shortenedUnreadNotificationCount" overlap offset-y="7" :value="hasAnyNotifications">
              <v-icon color="white">mdi-bell-outline</v-icon>
            </v-badge>
          </v-btn>
        </template>
      </NotificationsTopnavPopupPanel>
      <v-btn v-else icon :to="{ name: 'notifications' }">
        <v-badge color="error" :content="shortenedUnreadNotificationCount" overlap offset-y="7" :value="hasAnyNotifications">
          <v-icon color="white">mdi-bell-outline</v-icon>
        </v-badge>
      </v-btn>
    </div>

    <v-menu v-if="isAuthenticated" left bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <!-- <v-icon>mdi-dots-vertical</v-icon> -->
          <v-badge :value="showRealtimeDisconnectedIndicator" bottom color="warning" dot offset-x="6" offset-y="6">
            <v-avatar :size="40">
              <v-img :src="profilePicUrl" alt="Profile pic" />
            </v-avatar>
          </v-badge>
        </v-btn>
      </template>

      <v-list>
        <template v-for="(route, i) in routes">
          <v-list-item v-if="route.name" :key="route.name" :to="route" exact>
            <v-list-item-content>
              <v-badge
                bottom
                dot
                :value="showRealtimeDisconnectedIndicator && route.meta && route.meta.showRealtimeIndicator === true"
                color="warning"
                offset-x="10"
                offset-y="15"
              >
                <v-list-item-title class="route-title">
                  {{ route.meta && route.meta.title ? route.meta.title : route.name }}
                </v-list-item-title>
              </v-badge>
            </v-list-item-content>
          </v-list-item>

          <v-divider v-else-if="route.isDivider" :key="`divider-${i}`" />

          <v-list-item v-else :key="`unknown-${i}`">Unknown</v-list-item>
        </template>

        <v-divider />

        <v-list-item @click.prevent="$emit('logout')">
          <v-list-item-title>Sign Out</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-skeleton-loader v-else type="avatar" :boilerplate="!authBusyLoading || authHasError" />

    <v-app-bar-nav-icon @click.stop="$emit('toggle-sidenav')"></v-app-bar-nav-icon>
  </v-app-bar>
</template>

<style scoped>
.route-title {
  text-transform: capitalize;
}
</style>
