import { createApiInstance } from '@/infrastructure/apis/apiInstance'

export default {
  namespaced: true,

  state: () => ({
    users: [],
  }),

  getters: {},

  mutations: {
    setUsers(state, { users }) {
      state.users = users || []
    },
  },

  actions: {
    async fetchFakeError(context, { errorType }) {
      const response = await createApiInstance().get(`/Admin/fake-error?errorType=${errorType}`)
      return response.data
    },

    async fetchUsers(context) {
      const response = await createApiInstance().get(`/Admin/users`)
      context.commit('setUsers', { users: response.data })
    },
  },
}
