import { createApiInstance } from '@/infrastructure/apis/apiInstance'

/**
 * @typedef {Object} UserDependentResponse
 * @property {string} type
 * @property {string} userId
 * @property {string} fullName
 */

export default {
  namespaced: true,
  state: () => ({
    /** UserDependentResponse[] */
    dependents: [],
  }),
  getters: {},
  mutations: {
    setUserDependents(state, dependents) {
      state.dependents = dependents
    },
  },
  actions: {
    async fetchUserDependents(context) {
      const response = await createApiInstance().get('/UserDependents')
      context.commit('setUserDependents', response.data)
    },
    async addUserDependent(context, { fullName, dependentType }) {
      await createApiInstance().post('/UserDependents', { fullName: fullName, type: dependentType })
      await context.dispatch('fetchUserDependents')
    },
    async removeUserDependent(context, { dependentUserId }) {
      await createApiInstance().delete(`/UserDependents/${dependentUserId}`)
      await context.dispatch('fetchUserDependents')
    },
  },
}
