import config from '@/config'

function autoFillRouteMetaTitleFromName(route) {
  if (!route.meta) route.meta = {}

  route.meta.title = route.name.replace('admin-', '').replace(/-/g, ' ')

  return route
}

export default [
  {
    path: 'feedback',
    name: 'admin-feedback',
    component: () => import(/* webpackChunkName: "admin" */ '@/infrastructure/userFeedbacks/views/UserFeedbackForAdminView'),
  },

  {
    path: 'command-execution-events',
    name: 'admin-command-execution-events',
    meta: {
      pageLayoutProps: config.PageLayouts.wideFluid,
    },
    component: () => import(/* webpackChunkName: "admin" */ '@/infrastructure/admin/components/CommandExecutionEvents'),
  },

  {
    path: 'users',
    name: 'admin-users',
    component: () => import(/* webpackChunkName: "admin" */ '@/infrastructure/admin/components/AdminUsersList'),
  },
].map((route) => autoFillRouteMetaTitleFromName(route))
