import config from '@/config'

let auth0Instance = null

const recentLoginsStateKey = 'recent-logins'

export default {
  namespaced: true,
  state: () => ({
    authUser: undefined,
    authUserId: undefined,
    hasOfficialUserId: false,
    authUserRoles: [],
    authToken: undefined,
    authIsAuthenticated: false,
    authBusyLoading: true,
    authTakingLong: false,
    authFullError: null,

    recentLogins: JSON.parse(localStorage.getItem(recentLoginsStateKey) || '[]'),
  }),

  getters: {
    authHasError: (state) => !!state.authFullError,
    authUserId: (state) => state.authUserId,
    authUserIsAdmin: (state) => state.authUserRoles && state.authUserRoles.findIndex((r) => r === config.AdminRoleName) !== -1,
  },

  mutations: {
    setAuth0Instance(state, instance) {
      auth0Instance = instance
    },

    setAuthTakingLong(state) {
      if (state.authToken) console.error('setAuthTakingLong should actually not be called when authToken is set')

      state.authTakingLong = true
    },

    setAuthError(state, { fullError }) {
      console.error('setAuthError', { fullError })

      state.authFullError = fullError
    },
    clearAuthError(state) {
      state.authFullError = null
    },

    setAuthUserAndToken(state, { user, token, roles, connectionName, firepumaUserId }) {
      state.authUser = user
      state.authUserId = firepumaUserId || (user ? user.sub : undefined)
      state.authUserRoles = roles || []
      state.authToken = token
      state.authIsAuthenticated = !!(user && token)
      state.authBusyLoading = false

      if (token) {
        state.authTakingLong = false
      }

      if (token && user && user.email) {
        const recentLogin = {
          email: user.email,
          picture: user.picture,
          connectionName: connectionName,
        }

        const foundIdx = state.recentLogins.findIndex((u) => u.email === user.email)

        if (foundIdx === -1) {
          state.recentLogins.push(recentLogin)
        } else {
          state.recentLogins[foundIdx] = recentLogin
        }

        localStorage.setItem(recentLoginsStateKey, JSON.stringify(state.recentLogins))
      }
    },

    setAuthUserId(state, { authUserId, hasOfficialUserId }) {
      state.authUserId = authUserId
      state.hasOfficialUserId = hasOfficialUserId
    },

    authRedirectToConnection(state, { connectionName, email }) {
      auth0Instance.loginWithRedirect({
        connection: connectionName,
        login_hint: email,
      })
    },

    authLogout() {
      auth0Instance.logout({
        returnTo: window.location.origin,
      })
    },
  },

  actions: {
    initializeAuth(context, instance) {
      context.commit('setAuth0Instance', instance)
      setTimeout(() => {
        if (!context.state.authToken) {
          context.commit('setAuthTakingLong')
        }
      }, 8000)
    },
  },
}
