import Vue from 'vue'
import Vuex from 'vuex'
import config from '@/config'
import { readSavedState, useLocalStoragePlugin } from './plugins/localStoragePlugin'

import localCache from '../../infrastructure/localCache/storeModule'

import auth from '../../infrastructure/auth/storeModule'

import admin from '../../infrastructure/admin/storeModule'

import userProfile from '../../infrastructure/userProfile/storeModule'
import userNotices from '../../infrastructure/userNotices/storeModule'
import userFeedbacks from '../../infrastructure/userFeedbacks/storeModule'

import notifications from '../../features/notifications/storeModule'

import userDependents from '../../features/userDependents/storeModule'

import items from '../../features/items/storeModule'

import friends from '../../features/friends/storeModule'

Vue.use(Vuex)

const stateKeyPwaIsInstalled = 'pwa-is-installed'

let errorId = 0
let toastId = 0

/**
 * @typedef {Object} UserToImpersonate
 * @property {string} userId
 * @property {string} fullName
 */

const store = new Vuex.Store({
  strict: !config.IsProductionEnvironment,

  modules: {
    localCache,
    auth,
    admin,
    userProfile,
    userNotices,
    userFeedbacks,
    notifications,
    userDependents,
    items,
    friends,
  },

  state: {
    pwaIsInstalled: sessionStorage.getItem(stateKeyPwaIsInstalled) === '1',

    errors: [],
    toasts: [],

    pageTitle: config.AppName,

    /** UserToImpersonate */
    userToImpersonate: null,
  },

  mutations: {
    setPwaIsInstalled(state, { isInstalled }) {
      if (isInstalled) {
        // only setting pwaIsInstalled in when true is good, otherwise a false will overwrite a true that got loaded from the session (sessionStorage)
        state.pwaIsInstalled = true
        sessionStorage.setItem(stateKeyPwaIsInstalled, '1')
      }

      // We cannot remove it because it will behave weird. If user launches from PWA, navigates to another page, and refresh the app, it will be gone
      // else {
      //   state.pwaIsInstalled = false
      //   sessionStorage.removeItem(stateKeyPwaIsInstalled)
      // }
    },

    addError(state, error) {
      console.error('addError', { error, errorType: typeof error, response: error.response })

      const errorLines = []

      if (typeof error === 'string') {
        errorLines.push(error)
      }

      if (error.response && error.response.data && typeof error.response.data === 'string') {
        errorLines.push(error.response.data)
      }

      if (error.response && error.response.data && error.response.data.errors) {
        try {
          if (Array.isArray(error.response.data.errors)) {
            error.response.data.errors.forEach((err) => {
              const parts = []
              if (err.message) parts.push(err.message)
              // if (err.code) parts.push(`[${err.code}]`)
              if (parts.length > 0) {
                errorLines.push(parts.join(' '))
              }
            })
          } else if (Object.values(error.response.data.errors).length > 0) {
            Object.values(error.response.data.errors).forEach((errStr) => {
              errorLines.push(errStr)
            })
          }
        } catch {
          // ignore
        }
      }

      if (error.response && error.response.data && error.response.data.title) {
        errorLines.push(error.response.data.title)
      }

      if (error.message && errorLines.length === 0) errorLines.push(error.message)

      errorId++
      const combinedMessage = errorLines.join('\n')

      const duplicateFoundIndex = state.errors.findIndex((e) => e.message === combinedMessage)
      if (duplicateFoundIndex >= 0) {
        console.warn('Skipping to add duplicate error', { combinedMessage, duplicateFoundIndex, errors: state.errors })
        return
      }

      state.errors.push({ id: String(errorId), message: combinedMessage, fullError: JSON.stringify(error) })
    },

    removeError(state, error) {
      const idx = state.errors.indexOf(error)
      // console.debug('idx', idx)
      if (idx !== -1) {
        state.errors.splice(idx, 1)
      }
    },

    addToast(state, { message, color, timeout, actionText, actionFunction }) {
      toastId++
      state.toasts.push({
        id: toastId,
        message: message,
        color: color,
        timeout: timeout,
        actionText: actionText,
        actionFunction: actionFunction,
      })
    },

    removeToast(state, toast) {
      const idx = state.toasts.indexOf(toast)
      // console.debug('idx', idx)
      if (idx !== -1) {
        state.toasts.splice(idx, 1)
      }
    },

    setUserToImpersonate(state, { userId, fullName }) {
      state.userToImpersonate = { userId, fullName }
    },

    removeUserToImpersonate(state) {
      state.userToImpersonate = null
    },

    async setPageTitle(state, title) {
      const fullTitle = `${title} | ${config.AppName}`

      state.pageTitle = fullTitle

      await Vue.nextTick()
      document.title = fullTitle
    },
  },
})

const savedState = readSavedState()
if (savedState) {
  store.replaceState(Object.assign({}, store.state, savedState))
}

useLocalStoragePlugin(store)

export default store
