<script>
import BirthdayPickerCard from '@/features/users/components/BirthdayPickerCard'

export default {
  components: { BirthdayPickerCard },
}
</script>

<template>
  <BirthdayPickerCard @birthday-saved="$emit('action-complete')" />
</template>
