import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { ref, watch } from '@vue/composition-api'

dayjs.extend(relativeTime)

export function formatDate(date, format) {
  return dayjs(date).format(format)
}

export function formatRelativeTimeAgo(date) {
  return dayjs(date).fromNow()
}

const relativeTimeAgosToUpdate = []

setInterval(() => {
  relativeTimeAgosToUpdate.forEach((r) => {
    r.ref.value = formatRelativeTimeAgo(r.originalDateRef.value)
  })
}, 15 * 1000)

export function reactiveFormatRelativeTimeAgo(dateRef) {
  const timeAgo = ref(formatRelativeTimeAgo(dateRef.value))

  const futureContainer = {
    originalDateRef: dateRef,
    ref: timeAgo,
  }

  watch(dateRef, (newValue) => {
    futureContainer.ref.value = newValue ? formatRelativeTimeAgo(newValue) : ''
  })

  relativeTimeAgosToUpdate.push(futureContainer)

  return timeAgo
}
