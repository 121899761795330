console.debug('process.env', process.env)

import Vue from 'vue'
import App from './App.vue'
import './infrastructure/registerServiceWorker'
import router from './infrastructure/router'
import vuetify from './infrastructure/plugins/vuetify'
import './infrastructure/plugins/vueCompositionApi'

import config from './config'
import { useAuth0 } from './infrastructure/auth'
import { initializeAppInsights } from '@/infrastructure/plugins/appInsights'

import store from './infrastructure/store'

import './assets/theme.css'
import './assets/vue-transitions.css'
import './assets/animations.css'

console.debug({ config })

initializeAppInsights()

// noinspection JSIgnoredPromiseFromCall
store.dispatch(
  'auth/initializeAuth',
  useAuth0({
    domain: config.Auth0.Domain,
    clientId: config.Auth0.ClientId,
    audience: config.Auth0.Audience,
    onRedirectCallback: (appState) => {
      router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname)
    },
  })
)

Vue.config.productionTip = false

const isPwaInstalled = !!(location.search && (location.search.includes('?pwa=1') || location.search.includes('&pwa=1')))
store.commit('setPwaIsInstalled', { isInstalled: isPwaInstalled })

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app')
