<script>
import store from '@/infrastructure/store'

export default {
  data() {
    return {
      days: Array.from({ length: 31 }, (v, k) => k + 1),
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],

      selectedDay: '',
      selectedMonth: '',

      busyLoading: false,
      busySaving: false,
    }
  },

  mounted() {
    this.loadBirthday()
  },

  methods: {
    async loadBirthday() {
      this.busyLoading = true
      try {
        const { day, month } = await store.dispatch('userProfile/loadBirthDayAndMonth')
        if (day && month) {
          this.selectedDay = day
          this.selectedMonth = month
        }
      } finally {
        this.busyLoading = false
      }
    },
    async saveBirthday({ day, month }) {
      if (day && month) {
        this.busySaving = true
        try {
          await store.dispatch('userProfile/saveBirthDayAndMonth', { day, month })
          this.$emit('birthday-saved')
        } finally {
          this.busySaving = false
        }
      }
    },

    onSelectedDayChanged(day) {
      this.saveBirthday({ day: day, month: this.selectedMonth })
    },
    onSelectedMonthChanged(month) {
      this.saveBirthday({ day: this.selectedDay, month: month })
    },
  },
}
</script>

<template>
  <v-card>
    <v-card-title>Your birthday</v-card-title>
    <v-card-subtitle>Fill in your birthday to allow us to send your friends and family a reminder every year </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col cols="5">
          <v-select
            label="Day"
            v-model="selectedDay"
            :items="days"
            filled
            hide-details
            :disabled="busyLoading || busySaving"
            :loading="busyLoading || busySaving"
            @change="onSelectedDayChanged($event)"
          ></v-select>
        </v-col>
        <v-col cols="7">
          <v-select
            label="Month"
            v-model="selectedMonth"
            :items="months"
            filled
            hide-details
            :disabled="busyLoading || busySaving"
            :loading="busyLoading || busySaving"
            @change="onSelectedMonthChanged($event)"
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
