<script>
import { defineComponent, ref, watch } from '@vue/composition-api'

import { useOnlineDetection } from './useOnlineDetection'

export default defineComponent({
  setup() {
    const { isOnline } = useOnlineDetection()
    const isAlertVisible = ref(true)

    watch(isOnline, () => {
      // make alert visible again when isOnline changes
      isAlertVisible.value = true
    })

    return {
      isOnline,
      isAlertVisible,
    }
  },
})
</script>

<template>
  <div>
    <v-card v-if="!isOnline && isAlertVisible" class="mb-3">
      <v-card-text>
        <v-alert v-model="isAlertVisible" type="warning" outlined dismissible class="mb-0">
          You are currently offline, please check your internet connectivity
        </v-alert>
      </v-card-text>
    </v-card>
  </div>
</template>
