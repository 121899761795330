import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../../features/home/views/HomeView.vue'
import config from '@/config'
import playgroundChildRoutes from '@/playground/playgroundChildRoutes'
import adminChildRoutes from '@/infrastructure/admin/adminChildRoutes'

Vue.use(VueRouter)

const adminRole = config.AdminRoleName

export default [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      icon: 'mdi-home',
      bottomNav: true,
    },
  },

  {
    path: '/dependents',
    name: 'dependents',
    component: () => import('@/features/userDependents/views/DependentsView.vue'),
    meta: {
      icon: 'mdi-human-male-child',
      navGroup: 'user-avatar-dropdown',
      hideWhenImpersonating: true,
    },
  },

  {
    path: '/my-items',
    name: 'my-items',
    component: () => import('@/features/items/views/MyItemsView.vue'),
    meta: {
      title: 'My items',
      icon: 'mdi-format-list-bulleted-square',
      bottomNav: true,
    },
  },

  {
    path: '/my-archived-items',
    name: 'my-archived-items',
    component: () => import('@/features/items/views/MyArchivedItemsView'),
    meta: {
      navHide: true,
      title: 'My archived items',
      icon: 'mdi-format-list-bulleted-square',
    },
  },

  {
    path: '/friends',
    name: 'friends',
    component: () => import('@/features/friends/views/FriendsView.vue'),
    meta: {
      icon: 'mdi-account-group',
      bottomNav: true,
    },
  },

  {
    path: '/friend/:userId',
    props: true,
    component: () => import('@/infrastructure/router/views/RouterView.vue'),
    meta: {
      navHide: true,
    },
    children: [
      {
        path: '',
        name: 'friend-with-items',
        component: () => import('@/features/friends/views/FriendWithItemsView.vue'),
        meta: {
          title: 'Friend with items',
        },
        props: (route) => ({ userId: route.params.userId }),
      },
      {
        path: 'with-archived-items',
        name: 'friend-with-archived-items',
        component: () => import('@/features/friends/views/FriendWithArchivedItemsView.vue'),
        meta: {
          title: 'Friend with archived items',
        },
        props: (route) => ({ userId: route.params.userId, archivedItemsMode: true }),
      },
      {
        path: 'with-claimed-items',
        name: 'friend-with-claimed-items',
        component: () => import('@/features/friends/views/FriendWithClaimedItemsView.vue'),
        meta: {
          title: 'Friend with claimed items',
        },
        props: (route) => ({ userId: route.params.userId, claimedItemsMode: true }),
      },
    ],
  },

  {
    path: '/secret-santa',
    name: 'secret-santa',
    component: () => import('@/features/friends/views/SecretSantaView.vue'),
    meta: {
      navHide: true, // for now the feature is disabled
      icon: 'mdi-gift-outline',
    },
  },
  {
    path: '/secret-santa-event/:eventId',
    name: 'secret-santa-event',
    props: true,
    component: () => import('@/features/friends/views/SecretSantaEventView.vue'),
    meta: {
      navHide: true,
    },
  },

  {
    path: '/notifications',
    component: () => import('@/infrastructure/router/views/RouterView.vue'),
    meta: {
      navHide: true,
      icon: 'mdi-bell-outline',
    },
    children: [
      {
        path: '',
        name: 'notifications',
        component: () => import('@/features/notifications/views/UnreadNotificationsView.vue'),
      },
      {
        path: 'read',
        name: 'read-notifications',
        component: () => import('@/features/notifications/views/ReadNotificationsView.vue'),
        meta: {
          title: 'Read notifications',
        },
      },
    ],
  },
  {
    path: '/user-push-notification/:notificationId',
    component: () => import('@/infrastructure/router/views/RouterView.vue'),
    meta: {
      navHide: true,
      icon: 'mdi-bell-outline',
    },
    children: [
      {
        path: '',
        name: 'user-push-notifications',
        component: () => import('@/features/notifications/views/UserPushNotificationView.vue'),
        props: true,
      },
    ],
  },

  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/features/users/views/ProfileView.vue'),
    meta: {
      navGroup: 'user-avatar-dropdown',
    },
  },

  {
    path: '/preferences',
    name: 'preferences',
    component: () => import('@/features/users/views/PreferencesView.vue'),
    meta: {
      navGroup: 'user-avatar-dropdown',
    },
  },

  {
    isDivider: true,
    meta: {
      navGroup: 'user-avatar-dropdown',
    },
  },

  {
    path: '/feedback',
    name: 'feedback',
    component: () => import('@/infrastructure/userFeedbacks/views/UserFeedbackForCreatorView.vue'),
    meta: {
      navGroup: 'user-avatar-dropdown',
      showRealtimeIndicator: true,
    },
  },

  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '@/features/about/views/AboutView.vue'),
    meta: {
      navGroup: 'user-avatar-dropdown',
      showRealtimeIndicator: true,
    },
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "about" */ '@/features/about/views/PrivacyPolicyView.vue'),
    meta: {
      navHide: true,
      allowAnonymous: true,
    },
  },
  {
    path: '/terms-of-service',
    name: 'terms-of-service',
    component: () => import(/* webpackChunkName: "about" */ '@/features/about/views/TermsOfServiceView.vue'),
    meta: {
      navHide: true,
      allowAnonymous: true,
    },
  },

  {
    path: '/playground',
    name: 'playground',
    component: () => import(/* webpackChunkName: "playground" */ '@/playground/PlaygroundView.vue'),
    meta: {
      requiresRole: adminRole,
      icon: 'mdi-flask-empty-outline',
      title: 'Playground',
    },
    children: playgroundChildRoutes,
  },

  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/infrastructure/admin/views/AdminView.vue'),
    meta: {
      requiresRole: adminRole,
      pageLayoutProps: config.PageLayouts.wideFluid,
      icon: 'mdi-account-hard-hat',
    },
    children: adminChildRoutes,
  },

  {
    path: '/share-target', // specified in the public/manifest.json file, under share_target field
    name: 'share-target',
    component: () => import('@/infrastructure/pwa/views/PwaShareTargetView.vue'),
    meta: {
      navHide: true,
    },
  },

  {
    path: '*',
    component: () => import(/* webpackChunkName: "views" */ '@/infrastructure/errors/views/PageNotFoundView.vue'),
    meta: {
      navHide: true,
      title: 'Page not found',
      isPageNotFound: true,
    },
  },
]
