import { createApiInstance } from '@/infrastructure/apis/apiInstance'
import Vue from 'vue'

/**
 * @typedef {Object} FriendOfUserResponse
 * @property {string} friendFullName
 * @property {string} friendEmail
 */

const recentFriendsStateKey = 'recent-friends'

export default {
  namespaced: true,
  state: () => ({
    /** FriendOfUserResponse[] */
    allFriendRequests: [],

    /** any[] */
    recentFriends: JSON.parse(localStorage.getItem(recentFriendsStateKey) || '[]'),

    upcomingBirthdays: [],

    secretSantaEventsRepository: {},
  }),
  getters: {
    acceptedFriendRequests: (state) => state.allFriendRequests.filter((f) => f.status === 'AcceptedByActor' || f.status === 'AcceptedByOtherPerson'),
    waitingOnActorFriendRequests: (state) => state.allFriendRequests.filter((f) => f.status === 'WaitingOnActor'),
    waitingOnOtherPersonFriendRequests: (state) => state.allFriendRequests.filter((f) => f.status === 'WaitingOnOtherPerson'),
    rejectedByActorFriendRequests: (state) => state.allFriendRequests.filter((f) => f.status === 'RejectedByActor'),

    getFriendByUserId: (state) => (userId) => state.allFriendRequests.find((f) => f.friendUserId === userId),

    getSecretSantaEventById: (state) => (id) => state.secretSantaEventsRepository[id],
  },
  mutations: {
    setAllFriendRequests(state, allFriendRequests) {
      state.allFriendRequests = allFriendRequests
    },
    setFriendRequest(state, { friendRequest, dontAutoAddToArray }) {
      const items = [].concat(state.allFriendRequests || [])
      const foundIdx = items.findIndex((x) => x.requestId === friendRequest.requestId)
      if (foundIdx !== -1) {
        Vue.set(items, foundIdx, friendRequest)
      } else {
        if (!dontAutoAddToArray) {
          items.push(friendRequest)
        }
      }

      state.allFriendRequests = items
    },

    addRecentFriend(state, { userId, fullName, picture }) {
      const existingIdx = state.recentFriends.findIndex((f) => f.userId === userId)
      if (existingIdx !== -1) {
        state.recentFriends.splice(existingIdx, 1)
      }

      state.recentFriends.unshift({ userId, fullName, picture })

      while (state.recentFriends.length > 3) {
        state.recentFriends.pop()
      }

      localStorage.setItem(recentFriendsStateKey, JSON.stringify(state.recentFriends))
    },

    setUpcomingFriendBirthdays(state, upcomingBirthdays) {
      state.upcomingBirthdays = upcomingBirthdays
    },

    replaceSecretSantaEvent(state, { event }) {
      state.secretSantaEventsRepository = { ...state.secretSantaEventsRepository, [event.id]: event }
    },
  },
  actions: {
    async searchUsersForInviting(context, { searchPhrase }) {
      const response = await createApiInstance().get(`/Friends/search-for-inviting/by-phrase/${searchPhrase}`)
      return response.data
    },
    async createFriendRequest(context, { userId }) {
      await createApiInstance().post(`/Friends/${userId}`)
      await context.dispatch('fetchAllFriendRequests')
    },
    async acceptFriendRequest(context, { friendUserOrRequestId, isRequestId, userToImpersonateId }) {
      await createApiInstance({ userToImpersonateId }).put(`/Friends/${friendUserOrRequestId}/accept?isRequestId=${isRequestId ? 'true' : 'false'}`)
      await context.dispatch('fetchAllFriendRequests')
    },
    async rejectFriendRequest(context, { friendUserOrRequestId, isRequestId, userToImpersonateId }) {
      await createApiInstance({ userToImpersonateId }).put(`/Friends/${friendUserOrRequestId}/reject?isRequestId=${isRequestId ? 'true' : 'false'}`)
      await context.dispatch('fetchAllFriendRequests')
    },
    async unfriendFriendRequest(context, { friendUserOrRequestId, isRequestId, userToImpersonateId }) {
      await createApiInstance({ userToImpersonateId }).put(`/Friends/${friendUserOrRequestId}/unfriend?isRequestId=${isRequestId ? 'true' : 'false'}`)
      await context.dispatch('fetchAllFriendRequests')
    },
    async fetchAllFriendRequests(context) {
      const response = await createApiInstance().get('/Friends?filter=All')
      context.commit('setAllFriendRequests', response.data)
    },
    async fetchOnlyAcceptedFriendRequests(context) {
      const response = await createApiInstance().get('/Friends?filter=Accepted')
      context.commit('setAllFriendRequests', response.data)
    },
    async fetchUpcomingBirthdays(context) {
      const response = await createApiInstance().get('/Friends/upcoming-birthdays')
      context.commit('setUpcomingFriendBirthdays', response.data)
    },
    async fetchFriendRequest(context, { friendUserId }) {
      const response = await createApiInstance().get(`/Friends/${friendUserId}`)
      context.commit('setFriendRequest', { friendRequest: response.data })
    },

    async createSecretSantaInvite(context, { userIds, eventName, eventDate, budgetAmount }) {
      await createApiInstance().post('/SecretSanta', {
        userIds: userIds,
        eventName: eventName,
        eventDate: eventDate,
        budgetAmount: budgetAmount,
      })
    },
    async fetchSecretSantaEvent(context, { eventId }) {
      const response = await createApiInstance().get(`/SecretSanta/${eventId}`)
      context.commit('replaceSecretSantaEvent', { event: response.data })
      return response.data
    },
  },
}
