import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/infrastructure/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes.filter((r) => !r.isDivider),
})

router.afterEach((to) => {
  // noinspection JSUnresolvedVariable
  const routeTitle = to.meta && to.meta.title ? to.meta.title : to.name
  const capitalizedTitle = routeTitle.charAt(0).toUpperCase() + routeTitle.slice(1)
  store.commit('setPageTitle', capitalizedTitle)
})

export default router
