import { createApiInstance } from '@/infrastructure/apis/apiInstance'

export default {
  namespaced: true,

  state: () => ({
    nonDismissedNotices: [],
  }),

  mutations: {
    setNonDismissedNotices(state, { nonDismissedNotices }) {
      state.nonDismissedNotices = nonDismissedNotices || []
    },
    removeNonDismissedNotice(state, { notice }) {
      const idx = state.nonDismissedNotices.indexOf(notice)
      if (idx !== -1) {
        state.nonDismissedNotices.splice(idx, 1)
      }
    },
  },

  actions: {
    async loadNonDismissedNotices(context) {
      const response = await createApiInstance().get(`/UserNotices`)
      context.commit('setNonDismissedNotices', { nonDismissedNotices: response.data })
    },
    async dismissNotice(context, { notice }) {
      context.commit('removeNonDismissedNotice', { notice: notice })
      await createApiInstance().delete(`/UserNotices/${notice.type}`)
    },
  },
}
