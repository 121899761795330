function autoFillRouteMetaTitleFromName(route) {
  if (!route.meta) route.meta = {}

  route.meta.title = route.name.replace('playground-', '').replace(/-/g, ' ')

  return route
}

export default [
  {
    path: 'image-editor',
    name: 'playground-image-editor',
    component: () => import(/* webpackChunkName: "playground" */ './components/PlayImageEditor.vue'),
  },

  {
    path: 'api-errors',
    name: 'playground-api-errors',
    component: () => import(/* webpackChunkName: "playground" */ './components/PlayApiErrors.vue'),
  },

  {
    path: 'shop-stuff',
    name: 'playground-shop-stuff',
    component: () => import(/* webpackChunkName: "playground" */ './components/PlayShopStuff.vue'),
  },

  {
    path: 'auth-stuff',
    name: 'playground-auth-stuff',
    component: () => import(/* webpackChunkName: "playground" */ './components/PlayAuthStuff.vue'),
  },

  {
    path: 'notifications',
    name: 'playground-notifications',
    component: () => import(/* webpackChunkName: "playground" */ './components/PlayNotifications.vue'),
  },

  {
    path: 'my-items',
    name: 'playground-my-items',
    component: () => import(/* webpackChunkName: "playground" */ './components/PlayMyItems.vue'),
  },
  {
    path: 'friend-items',
    name: 'playground-friend-items',
    component: () => import(/* webpackChunkName: "playground" */ './components/PlayFriendItems.vue'),
  },
  {
    path: 'my-items-vs-friend-items',
    name: 'playground-my-items-vs-friend-items',
    component: () => import(/* webpackChunkName: "playground" */ './components/PlayMyItemsVsFriendItems.vue'),
  },

  {
    path: 'friend-picker-dialog',
    name: 'playground-friend-picker-dialog',
    component: () => import(/* webpackChunkName: "playground" */ './components/PlayFriendPickerDialog.vue'),
  },
].map((route) => autoFillRouteMetaTitleFromName(route))
