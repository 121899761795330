<script>
import store from '@/infrastructure/store'
import config from '@/config'

export default {
  data() {
    return {
      appName: config.AppName,
    }
  },

  computed: {
    recentLogins() {
      return store.state.auth.recentLogins || []
    },

    loginCards() {
      const buttons = []

      for (let i = 0; i < this.recentLogins.length; i++) {
        const recentLogin = this.recentLogins[i]
        const connection = config.Auth0.Connections.find((con) => con.name === recentLogin.connectionName)
        buttons.push({
          email: recentLogin.email,
          image: recentLogin.picture,
          connection: connection,
          onClick: () =>
            store.commit('auth/authRedirectToConnection', {
              connectionName: connection ? connection.name : undefined,
              email: recentLogin.email,
            }),
        })
      }

      return buttons
    },

    loginButtons() {
      return config.Auth0.Connections.map((con) => ({
        text: `Sign in with ${con.displayName}`,
        icon: con.icon,
        cssClass: con.cssClass,
        onClick: () => store.commit('auth/authRedirectToConnection', { connectionName: con.name }),
      }))
    },
  },
}
</script>

<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card>
          <v-card-title>
            <div class="mx-auto">Welcome to {{ appName }}</div>
          </v-card-title>
          <v-card-text>
            <div v-for="(card, i) in loginCards" :key="card.text" :class="{ 'mt-3': i > 0 }">
              <v-card @click="card.onClick">
                <v-card-text>
                  <v-row no-gutters align="center">
                    <v-avatar size="30">
                      <v-img v-if="card.image" :src="card.image"></v-img>
                    </v-avatar>

                    <v-col class="pl-3">
                      <div class="grey--text text--lighten-1">continue as</div>
                      <div class="success--text">
                        {{ card.email }}
                      </div>
                      <div v-if="card.connection" class="d-flex align-center" :class="card.connection.cssClass">
                        <v-icon size="16" class="mr-1" :class="card.connection.cssClass">{{ card.connection.icon }} </v-icon>
                        {{ card.connection.displayName }}
                      </div>
                    </v-col>

                    <!--                    <v-avatar @click.stop="removeCachedLogin(cachedLogin)">-->
                    <!--                      <v-icon color="grey lighten-1">fa fa-close</v-icon>-->
                    <!--                    </v-avatar>-->
                  </v-row>
                </v-card-text>
              </v-card>
            </div>

            <template v-if="loginCards.length > 0">
              <v-divider class="my-5" />
            </template>

            <div v-for="(button, i) in loginButtons" :key="button.text" :class="{ 'mt-3': i > 0 }">
              <v-btn block outlined x-large :class="button.cssClass" @click="button.onClick">
                <v-icon left>{{ button.icon }}</v-icon>
                {{ button.text }}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>

        <div class="pt-4"></div>

        <v-row>
          <v-col cols="6">
            <v-card :to="{ name: 'privacy-policy' }">
              <v-card-text class="text-center">Privacy Policy</v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card :to="{ name: 'terms-of-service' }">
              <v-card-text class="text-center">Terms of Service</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
