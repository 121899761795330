<script>
export default {
  props: {
    otherUserName: { type: String, required: true },
  },
}
</script>

<template>
  <v-alert :value="true" type="warning" dense class="mb-0">
    You are acting on behalf of {{ otherUserName }}

    <template v-slot:append>
      <v-btn outlined small @click="$emit('stop-impersonation')">
        <v-icon left>mdi-close-octagon</v-icon>
        Stop
      </v-btn>
    </template>
  </v-alert>
</template>
