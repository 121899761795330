const stateKey = 'store'

export function readSavedState() {
  const savedState = localStorage.getItem(stateKey)
  if (!savedState) return undefined
  return JSON.parse(savedState)
}

export function useLocalStoragePlugin(store) {
  store.subscribe((mutation, state) => {
    const keysToStore = ['userToImpersonate', 'localCache', 'userNotices']
    const snapshot = {}
    keysToStore.forEach((k) => {
      snapshot[k] = state[k]
    })
    localStorage.setItem(stateKey, JSON.stringify(snapshot))
  })
}
