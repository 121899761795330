<script>
import store from '@/infrastructure/store'

export default {
  data() {
    return {
      message: '',

      rules: {
        messageRequired: (value) => !!value || 'Message is required',
      },

      busySaving: false,
      formValid: false,
    }
  },

  methods: {
    clearForm() {
      this.message = ''

      this.$refs.form.reset()
    },

    async submitFeedback() {
      this.busySaving = true
      try {
        await store.dispatch('userFeedbacks/submitNewUserFeedback', { message: this.message })

        this.$emit('feedback-added')

        this.clearForm()
      } finally {
        this.busySaving = false
      }
    },
  },
}
</script>

<template>
  <v-card :loading="busySaving">
    <v-card-title class="primary--text" style="word-break: break-word; text-align: center">
      <strong>Tell us what is on your mind or what you think can be improved</strong>
    </v-card-title>

    <v-form ref="form" v-model="formValid" autocomplete="off">
      <v-card-text>
        <v-textarea label="Message" v-model="message" :rules="[rules.messageRequired]" filled clearable @click:clear="clearForm" />

        <div class="d-flex align-center">
          <v-spacer />
          <v-btn :disabled="!formValid || busySaving" :loading="busySaving" color="primary" @click="submitFeedback"> Submit feedback </v-btn>
        </div>
      </v-card-text>
    </v-form>
  </v-card>
</template>
