<script>
import config from '@/config'

export default {
  props: {
    authBusyLoading: { type: Boolean },
    authTakingLong: { type: Boolean },
    authFullError: { type: String },
  },

  data() {
    return {
      appName: config.AppName,
    }
  },
}
</script>

<template>
  <v-sheet v-if="!authFullError" class="pa-3">
    <v-skeleton-loader type="card"></v-skeleton-loader>
  </v-sheet>

  <v-card v-else-if="authFullError" :loading="authBusyLoading && !authFullError">
    <div class="pa-3">
      <v-alert type="error" :value="true" prominent class="mb-0">
        <div style="white-space: pre-line">{{ authFullError }}</div>
      </v-alert>
    </div>
  </v-card>

  <v-card v-else-if="authTakingLong" :loading="authBusyLoading && !authFullError">
    <div class="pa-3">
      <v-alert type="warning" :value="true" prominent>
        <div>Please check your internet connection</div>
        <div>Things are taking a bit longer than expected</div>
      </v-alert>
    </div>
  </v-card>
</template>
