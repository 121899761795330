<script>
import { getInitialsFromName } from '@/features/friends/services/friendNameHelper'

export default {
  props: {
    friends: { type: Array, required: true },
  },

  methods: {
    getInitials(name) {
      return getInitialsFromName(name)
    },
  },
}
</script>

<template>
  <v-card flat>
    <v-card-text>
      <div class="d-flex align-center justify-center">
        <router-link
          v-for="friend in friends"
          :key="friend.id"
          :to="{ name: 'friend-with-items', params: { userId: friend.userId } }"
          class="mx-2"
          style="text-decoration: none"
        >
          <v-avatar v-if="friend.picture" size="50">
            <v-img :src="friend.picture"></v-img>
          </v-avatar>
          <v-avatar v-else size="50" color="green">
            <span class="white--text text-h5">{{ getInitials(friend.fullName) }}</span>
          </v-avatar>
        </router-link>
      </div>
    </v-card-text>
  </v-card>
</template>
