<script>
export default {
  props: {
    routes: { type: Array, required: true },
  },

  mounted() {
    if (this.routes.length === 0) {
      console.error('Bottomnav is used but number of routes are 0')
    }
  },
}
</script>

<template>
  <v-bottom-navigation v-show="routes.length > 0" app shift>
    <v-btn v-for="route in routes" :key="route.name" :to="route" exact>
      <span>{{ route.meta && route.meta.title ? route.meta.title : route.name }}</span>

      <v-icon v-if="route.meta && route.meta.icon">{{ route.meta.icon }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>
