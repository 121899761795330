<script>
import AddBirthdayNotice from '@/features/home/components/notices/AddBirthdayNotice'
import GiveUserFeedbackNotice from '@/features/home/components/notices/GiveUserFeedbackNotice'
import config from '@/config'

export default {
  props: {
    noticeDate: { type: String, required: true },
    type: { type: String, required: true },
  },

  data() {
    return {
      isVisible: true,
    }
  },

  computed: {
    noticeContent() {
      switch (this.type) {
        case 'AddBirthday':
          return {
            text: `Add your birthday below, this will allow ${config.AppName} to send your friends and family a reminder every year. This can also be done on the Profile screen.`,
            component: AddBirthdayNotice,
          }

        case 'GiveUserFeedback':
          return {
            text: `Tell us what is on your mind or what you think can be improved. This can also be done via the Feedback screen.`,
            component: GiveUserFeedbackNotice,
          }
      }

      return {
        text: this.type,
      }
    },
  },

  watch: {
    isVisible(to) {
      if (!to) {
        this.fireDismissEvent()
      }
    },
  },

  methods: {
    fireDismissEvent() {
      this.$emit('dismissed')
    },

    onActionComplete() {
      this.fireDismissEvent()
    },
  },
}
</script>

<template>
  <v-card outlined>
    <v-card-text>
      <v-alert v-model="isVisible" dismissible type="warning" border="left" elevation="2" colored-border>
        <template v-slot:prepend>
          <v-icon color="warning" class="mr-2">mdi-alert-outline</v-icon>
        </template>

        <div>{{ noticeContent.text }}</div>
      </v-alert>

      <div v-if="noticeContent.component">
        <component :is="noticeContent.component" @action-complete="onActionComplete" />
      </div>
      <div v-else>
        {{ type }}
      </div>
    </v-card-text>
  </v-card>
</template>
