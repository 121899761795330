<script>
export default {
  props: {
    action: { type: Function, required: true },
  },

  data() {
    return {
      isExecuting: false,
    }
  },

  computed: {
    buttonEnabled() {
      return !this.isExecuting
    },
  },

  methods: {
    async onClick() {
      this.isExecuting = true
      try {
        await this.action()
      } finally {
        this.isExecuting = false
      }
    },
  },
}
</script>

<template>
  <v-btn v-bind="$attrs" v-on="$listeners" :disabled="!buttonEnabled" :loading="isExecuting" @click="onClick">
    <slot />
  </v-btn>
</template>
