import { appInsights } from '../plugins/appInsights'
import store from '@/infrastructure/store'

class Service {
  trackException(err, options) {
    let typesafeError = err instanceof Error ? err : new Error(err)

    const exception = Object.assign({}, { error: typesafeError }, options)

    appInsights.trackException(exception)
    appInsights.flush(false)
  }

  trackPageView({ name, uri, refUri, pageType, properties, measurements }) {
    const isLoggedIn = !!store.state.auth.authUserId
    appInsights.trackPageView({ name, uri, refUri, pageType, isLoggedIn, properties, measurements })
  }

  trackEvent({ name, properties, measurements }) {
    appInsights.trackEvent({ name, properties, measurements })
  }

  flush() {
    appInsights.flush(false)
  }
}

export const TelemetryService = new Service()
