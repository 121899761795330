import { createApiInstance } from '@/infrastructure/apis/apiInstance'

export default {
  namespaced: true,
  state: () => ({
    notificationsRepository: {},

    unreadNotificationCount: 0,

    unreadNotificationIds: [],
    readNotificationIds: [],
  }),
  getters: {
    unreadNotifications: (state) => state.unreadNotificationIds.map((id) => state.notificationsRepository[id]),
    readNotifications: (state) => state.readNotificationIds.map((id) => state.notificationsRepository[id]),
  },
  mutations: {
    setUnreadNotificationCount(state, count) {
      state.unreadNotificationCount = count
    },
    setUnreadNotifications(state, notifications) {
      notifications.forEach((notification) => (state.notificationsRepository[notification.notificationId] = notification))
      state.unreadNotificationIds = notifications.map((notification) => notification.notificationId)
    },
    setReadNotifications(state, notifications) {
      notifications.forEach((notification) => (state.notificationsRepository[notification.notificationId] = notification))
      state.readNotificationIds = notifications.map((notification) => notification.notificationId)
    },

    replaceNotification(state, { notification }) {
      state.notificationsRepository = { ...state.notificationsRepository, [notification.notificationId]: notification }
    },
  },
  actions: {
    async fetchUnreadNotificationCount(context) {
      const response = await createApiInstance().get('/Notifications/count')
      context.commit('setUnreadNotificationCount', response.data)
    },
    async fetchUnreadNotifications(context) {
      const response = await createApiInstance().get('/Notifications')
      context.commit('setUnreadNotifications', response.data)
    },
    async fetchReadNotifications(context) {
      const response = await createApiInstance().get('/Notifications?filter=Read')
      context.commit('setReadNotifications', response.data)
    },
    async fetchNotification(context, { notificationId }) {
      const response = await createApiInstance().get(`/Notifications/${notificationId}`)
      context.commit('replaceNotification', { notification: response.data })
    },
    async markNotificationRead(context, { notificationId }) {
      await createApiInstance().put(`/Notifications/${notificationId}/mark-read`)
      await Promise.all([
        context.dispatch('fetchNotification', { notificationId: notificationId }),
        context.dispatch('fetchUnreadNotificationCount'),
        context.dispatch('fetchUnreadNotifications'),
      ])
    },
    async markAllNotificationsRead(context) {
      await createApiInstance().put(`/Notifications/mark-all-read`)
      await Promise.all([
        context.dispatch('fetchUnreadNotificationCount'),
        context.dispatch('fetchUnreadNotifications'),
        context.dispatch('fetchReadNotifications'),
      ])
    },

    async getInAppPreferences() {
      const response = await createApiInstance().get('/Notifications/preferences/in-app')
      return response.data
    },
    async saveInAppPreferences(context, { switches }) {
      await createApiInstance().put('/Notifications/preferences/in-app', switches)
    },

    async getEmailPreferences() {
      const response = await createApiInstance().get('/Notifications/preferences/email')
      return response.data
    },
    async saveEmailPreferences(context, { switches }) {
      await createApiInstance().put('/Notifications/preferences/email', switches)
    },

    async getPushPreferences() {
      const response = await createApiInstance().get('/Notifications/preferences/push')
      return response.data
    },
    async savePushPreferences(context, { switches }) {
      await createApiInstance().put('/Notifications/preferences/push', switches)
    },
  },
}
