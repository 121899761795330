<script>
import UpcomingFriendBirthdays from '@/features/friends/components/UpcomingFriendBirthdays'
import UserNoticeList from '@/features/home/components/UserNoticeList'
import RecentFriendsCard from '@/features/home/components/RecentFriendsCard'
import store from '@/infrastructure/store'

export default {
  components: {
    UserNoticeList,
    UpcomingFriendBirthdays,
    RecentFriendsCard,
  },

  props: {
    rowCssClass: { type: String },
  },

  computed: {
    recentFriends: () => store.state.friends.recentFriends,
  },
}
</script>

<template>
  <div>
    <UserNoticeList />

    <v-card :to="{ name: 'my-items' }" :class="rowCssClass">
      <v-card-title>
        <div class="mx-auto">My Items</div>
      </v-card-title>

      <v-card-text>
        <div class="text-center">Things you'd love to receive</div>
      </v-card-text>
    </v-card>

    <v-card :class="rowCssClass">
      <v-card :to="{ name: 'friends' }" flat>
        <v-card-title>
          <div class="mx-auto">Friends</div>
        </v-card-title>

        <v-card-text>
          <div class="text-center">Things your friends would love to receive</div>
        </v-card-text>
      </v-card>

      <template v-if="recentFriends && recentFriends.length > 0">
        <v-divider />

        <RecentFriendsCard :friends="recentFriends" />
      </template>
    </v-card>

    <UpcomingFriendBirthdays />
  </div>
</template>
