import { ref } from '@vue/composition-api'
import store from '@/infrastructure/store'

export default function useAsyncLoading({ actionFactory, doNotAutoAddError }) {
  const isBusy = ref(false)
  const error = ref(undefined)

  const execute = async (...args) => {
    isBusy.value = true
    error.value = undefined

    try {
      await actionFactory(...args)
    } catch (e) {
      if (!doNotAutoAddError) {
        store.commit('addError', e)
      }
      error.value = e
    } finally {
      isBusy.value = false
    }
  }

  return {
    isBusy,
    error,

    execute,
  }
}
