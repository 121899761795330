<script>
import config from '@/config'
import routes from '@/infrastructure/router/routes'
import store from '@/infrastructure/store'
import { filterVisibleRoutes } from '@/infrastructure/router/services/routerHelperService'
import Sidenav from '@/infrastructure/router/components/Sidenav'
import Topnav from '@/infrastructure/router/components/Topnav'
import Bottomnav from '@/infrastructure/router/components/Bottomnav'
import useRealtimeSubscriptions from '@/infrastructure/realtime/realtimeSubscriptions'

export default {
  components: {
    Topnav,
    Bottomnav,
    Sidenav,
  },

  props: {
    isAuthenticated: { type: Boolean, required: true },
    authBusyLoading: { type: Boolean, required: true },
    authHasError: { type: Boolean, required: true },

    authUserId: { type: String },
    authUser: { type: Object },

    realtimeIsConnected: { type: Boolean, required: true },
    realtimeIsInStartupGracePeriod: { type: Boolean, required: true },
  },

  setup() {
    const { subscribeToRealtimeEvent } = useRealtimeSubscriptions()
    subscribeToRealtimeEvent('Notification:Created', () => store.dispatch('notifications/fetchUnreadNotificationCount'))
    subscribeToRealtimeEvent('Notifications:MarkedRead', () => store.dispatch('notifications/fetchUnreadNotificationCount'))
  },

  data() {
    return {
      appName: config.AppName,
      logoUrl: config.Branding.Logo40,
      showSidenav: null,
    }
  },

  computed: {
    sidenavRoutes() {
      return filterVisibleRoutes({
        routes,
        authUserRoles: this.userRoles,
        authUserIsImpersonating: this.isImpersonating,
        navGroup: null,
      })
    },
    userAvatarDropdownRoutes() {
      return filterVisibleRoutes({
        routes,
        authUserRoles: this.userRoles,
        authUserIsImpersonating: this.isImpersonating,
        navGroup: 'user-avatar-dropdown',
      })
    },
    bottomNavRoutes() {
      return filterVisibleRoutes({
        routes,
        authUserRoles: this.userRoles,
        authUserIsImpersonating: this.isImpersonating,
        bottomNav: true,
      })
    },
    userRoles() {
      return store.state.auth.authUserRoles
    },
    isImpersonating() {
      return !!store.state.userToImpersonate
    },
    unreadNotificationCount() {
      return store.state.notifications.unreadNotificationCount
    },
  },

  watch: {
    authUserId: {
      handler(to) {
        if (to) {
          store.dispatch('notifications/fetchUnreadNotificationCount')
        }
      },
      immediate: true,
    },
  },

  methods: {
    onLogoutClick() {
      store.commit('auth/authLogout')
    },
  },
}
</script>

<template>
  <div>
    <Sidenav
      :is-authenticated="isAuthenticated"
      :auth-busy-loading="authBusyLoading"
      :auth-has-error="authHasError"
      :routes="sidenavRoutes"
      :show-sidenav.sync="showSidenav"
    />

    <Topnav
      :app-name="appName"
      :is-authenticated="isAuthenticated"
      :auth-busy-loading="authBusyLoading"
      :auth-has-error="authHasError"
      :unread-notification-count="unreadNotificationCount"
      :realtime-is-connected="realtimeIsConnected"
      :realtime-is-in-startup-grace-period="realtimeIsInStartupGracePeriod"
      :routes="userAvatarDropdownRoutes"
      :logo-url="logoUrl"
      :profile-pic-url="isAuthenticated ? authUser.picture : undefined"
      @logout="onLogoutClick"
      @toggle-sidenav="showSidenav = !showSidenav"
    />

    <Bottomnav v-if="$vuetify.breakpoint.xsOnly" :routes="bottomNavRoutes" />
  </div>
</template>
