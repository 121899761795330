export function formatNotificationTemplateString(str, valsString) {
  if (!str || !valsString) return str

  try {
    const vals = JSON.parse(valsString)

    let finalStr = str
    Object.keys(vals).forEach((key) => {
      const placeholder = '{' + key + '}'
      let cnt = 0
      while (finalStr.includes(placeholder)) {
        finalStr = finalStr.replace(placeholder, vals[key])
        if (cnt++ > 100) break // avoid infinite loop
      }
    })

    return finalStr
  } catch (err) {
    console.error('Unable to format notification template string', err)
    return str
  }
}
