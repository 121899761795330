<script>
export default {
  props: {
    contentContainerAttrs: { type: Object, default: () => ({ fluid: false }) },
    contentColumnAttrs: { type: Object, default: () => ({ cols: '12', xl: '5', lg: '7', md: '8', sm: '9' }) },
  },
}
</script>

<template>
  <div>
    <slot name="app-nav" />

    <v-main>
      <slot name="top-banner" />

      <v-container v-bind="contentContainerAttrs">
        <v-row justify="center">
          <v-col v-bind="contentColumnAttrs">
            <slot name="content" />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>
