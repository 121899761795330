<script>
export default {
  props: {
    isAuthenticated: { type: Boolean, required: true },
    authBusyLoading: { type: Boolean, required: true },
    authHasError: { type: Boolean, required: true },
    routes: { type: Array, required: true },
    showSidenav: { type: Boolean },
  },

  data() {
    return {
      drawer: null,
    }
  },
}
</script>

<template>
  <v-navigation-drawer :value="showSidenav" @input="$emit('update:showSidenav', $event)" :clipped="$vuetify.breakpoint.lgAndUp" right app>
    <v-list v-if="isAuthenticated" dense>
      <template v-for="route in routes">
        <v-list-item :key="route.path" :to="route" active-class="primary--text" exact>
          <v-list-item-action class="mr-2">
            <v-icon v-if="route.meta && route.meta.icon" class="blue-grey--text text--lighten-1" style="font-size: 19px" aria-hidden="true">
              {{ route.meta.icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="route-title">
              {{ route.meta && route.meta.title ? route.meta.title : route.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <v-list v-else dense>
      <v-skeleton-loader type="list-item" :boilerplate="!authBusyLoading || authHasError" />
      <v-skeleton-loader type="list-item" :boilerplate="!authBusyLoading || authHasError" />
      <v-skeleton-loader type="list-item" :boilerplate="!authBusyLoading || authHasError" />
    </v-list>
  </v-navigation-drawer>
</template>

<style scoped>
.route-title {
  text-transform: capitalize;
}
</style>
