import Vue from 'vue'
import createAuth0Client from '@auth0/auth0-spa-js'
import store from '../store'
import userProfileApi from '@/features/users/apis/userProfile'
import config from '@/config'
import { TelemetryService } from '@/infrastructure/telemetry/telemetryService'

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname)

let instance

/** Returns the current instance of the SDK */
export const getInstance = () => instance

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({ onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, redirectUri = window.location.origin, ...options }) => {
  if (instance) return instance

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        token: undefined,
        user: {},
        auth0Client: null,
      }
    },
    watch: {
      token: {
        async handler(to) {
          if (to) {
            const response = await userProfileApi.recordUserAppStartup()
            if (response && response.userId) {
              store.commit('auth/setAuthUserId', { authUserId: response.userId, hasOfficialUserId: true })
            }
          }
        },
        immediate: true,
      },
    },
    methods: {
      processAndSetAuthError(error) {
        console.error('processAndSetAuthError', error)

        if (!error) {
          store.commit('auth/clearAuthError')
          return
        }

        const stringError = String(error)

        if (stringError.toLowerCase().includes('timeout')) {
          const errorMessage = 'We were unable to get you connected, please check your internet connection and then reload this page'
          store.commit('auth/setAuthError', { fullError: errorMessage })
          store.commit('addError', errorMessage)
        } else {
          const errorMessage = `Something went wrong while we tried to connect you\nPlease check your connectivity\n${String(error)}`
          store.commit('auth/setAuthError', { fullError: errorMessage })
          store.commit('addError', errorMessage)
        }
      },

      async loadUserAndToken() {
        this.user = await this.auth0Client.getUser()
        this.isAuthenticated = await this.auth0Client.isAuthenticated()

        let payloadForSetAuthUserAndToken

        if (this.isAuthenticated && this.user.email_verified) {
          this.token = await this.auth0Client.getTokenSilently()
          // this.token = (await this.getIdTokenClaims()).__raw
          const roles = this.user[`${config.Auth0.ClaimsNamespacePrefix}roles`]
          const connectionName = this.user[`${config.Auth0.ClaimsNamespacePrefix}connection_name`]
          const firepumaUserId = this.user[`${config.Auth0.ClaimsNamespacePrefix}firepuma_user_id`]
          payloadForSetAuthUserAndToken = {
            user: this.user,
            token: this.token,
            roles: roles,
            connectionName: connectionName,
            firepumaUserId: firepumaUserId,
          }
        } else {
          if (this.isAuthenticated && !this.user.email_verified) {
            store.commit('addError', 'Please verify your Auth0 email before continuing')
          }
          payloadForSetAuthUserAndToken = { user: undefined, token: undefined, roles: undefined }
        }

        if (payloadForSetAuthUserAndToken) {
          store.commit('auth/setAuthUserAndToken', payloadForSetAuthUserAndToken)

          const properties = {
            authUserId: store.state.auth.authUserId,
          }
          TelemetryService.trackEvent({ name: 'User loaded', properties: properties })
          TelemetryService.flush()
        } else {
          console.error('Unexpectedly have no value for payloadForSetAuthUserAndToken!')
        }
      },

      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        this.loading = true
        try {
          await this.auth0Client.handleRedirectCallback()
          await this.loadUserAndToken()
          this.processAndSetAuthError(null)
        } catch (e) {
          this.processAndSetAuthError(e)
          console.error(e)
        } finally {
          this.loading = false
        }
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o)
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o)
      },

      /** Logs the user out and removes their session on the authorization server */
      logout(o) {
        return this.auth0Client.logout(o)
      },
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      // Create a new instance of the SDK client using members of the given options object
      try {
        this.auth0Client = await createAuth0Client({
          ...options,
          client_id: options.clientId,
          redirect_uri: redirectUri,
          authorizeTimeoutInSeconds: config.Auth0.AuthorizeTimeoutInSeconds,
          cacheLocation: 'localstorage',
          useRefreshTokens: true,
        })
      } catch (e) {
        this.processAndSetAuthError(e)
        console.error('Unable to create Auth0 client', e)
        return
      }

      try {
        // If the user is returning to the app after authentication..
        if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
          // handle the redirect and retrieve tokens
          const { appState } = await this.auth0Client.handleRedirectCallback()

          this.processAndSetAuthError(null)

          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          onRedirectCallback(appState)
        }
      } catch (e) {
        this.processAndSetAuthError(e)
      } finally {
        // Initialize our internal authentication state
        await this.loadUserAndToken()
        this.loading = false
      }
    },
  })

  return instance
}
