<script>
export default {
  props: {
    unmetRequirement: { type: String, required: true },
  },
}
</script>

<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" xl="5" lg="7" md="8" sm="9">
        <v-alert :value="true" type="error" class="mt-5 py-10"> {{ unmetRequirement }}</v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>
