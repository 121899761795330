// console.debug('process.env', process.env); // logging in main.js instead

const defaultUrlsMap = {
  'https://wishlist.firepuma.com': {
    api: '/api',
    realtimeHub: 'https://wishlist-webapi-z3et7l22ma-ew.a.run.app/api/realtime/unified',
  },

  'http://localhost:8080': {
    api: '/api',
    realtimeHub: 'https://localhost:53569/api/realtime/unified',
  },

  'http://localhost': {
    api: 'UNIT_TEST_PLACEHOLDER',
    realtimeHub: 'UNIT_TEST_PLACEHOLDER',
  },
}

function getUrls() {
  // const apiUrl = localStorage.getItem('api-url') || `${location.origin}/api` //'https://scoutassist.co.za/api'

  const defaultUrls = defaultUrlsMap[window.location.origin]

  const apiUrlOverride = localStorage.getItem('api-url')

  if (apiUrlOverride) {
    console.warn('Using custom api url override', apiUrlOverride)
  }

  const realtimeHubUrlOverride = localStorage.getItem('realtime-hub-url')
  if (realtimeHubUrlOverride) {
    console.warn('Using custom realtime hub url override', realtimeHubUrlOverride)
  }

  const apiUrl = apiUrlOverride || defaultUrls?.api
  const realtimeHubUrl = apiUrlOverride || defaultUrls?.realtimeHub

  if (!apiUrl) {
    const err = `Unable to determine apiUrl, no mapping found for origin '${window.location.origin}' and also no api-url in localStorage`
    alert(err)
    console.error(err)
    throw new Error(err)
  }

  if (!realtimeHubUrl) {
    const err = `Unable to determine realtimeHubUrl, no mapping found for origin '${window.location.origin}' and also no realtime-hub-url in localStorage`
    alert(err)
    console.error(err)
    throw new Error(err)
  }

  return { apiUrl, realtimeHubUrl }
}

const { apiUrl, realtimeHubUrl } = getUrls()
console.debug('using', { apiUrl, realtimeHubUrl })

export default {
  IsProductionEnvironment: process.env.NODE_ENV === 'production',

  Version: process.env.VUE_APP_VERSION,

  AppInsightsInstrumentationKey: process.env.VUE_APP_APP_INSIGHTS_INSTRUMENTATION_KEY,
  AppInsightsEnabled: true,
  AppInsightsCloudRoleName: 'WebFrontend',

  ApiDefaultTimeoutSeconds: 60,

  ApiBaseUrl: apiUrl,
  RealtimeHubUrl: realtimeHubUrl,
  AppName: process.env.VUE_APP_DISPLAY_NAME,
  AppFrontendUrl: 'https://wishlist.firepuma.com',
  PrivacyPolicyContactEmail: 'francoishill11@gmail.com',

  Branding: {
    Logo40:
      'https://imaginary-z3et7l22ma-ew.a.run.app/thumbnail?url=https%3A%2F%2Fstorage.googleapis.com%2Fimaginary-public-images%2Fwishlist%2Ficons%2Fwishlistv3c-dandelion-icon.png&nocrop=true&width=40&height=40',
  },

  Auth0: {
    Domain: 'firepuma.eu.auth0.com',
    ClientId: 'WyjK53VjDnGJaDG4YRu4CLvWDGO7q1JR',
    Audience: 'https://wishlist.firepuma.com',
    ClaimsNamespacePrefix: 'https://firepuma.com/',
    AuthorizeTimeoutInSeconds: 30,
    Connections: [
      { name: 'Username-Password-Authentication', displayName: 'Password', icon: 'mdi-email', cssClass: '' },
      { name: 'google-oauth2', displayName: 'Google', icon: 'mdi-google', cssClass: 'indigo--text text--accent-2' },
      { name: 'facebook', displayName: 'Facebook', icon: 'mdi-facebook', cssClass: 'indigo--text' },
    ],
  },
  AdminRoleName: 'WishlistAdmin',

  Vapid: {
    PublicKey: process.env.VUE_APP_VAPID_PUBLIC_KEY,
  },

  MaxRating: 5,

  PageLayouts: {
    wideFluid: {
      contentContainerAttrs: { fluid: true },
      contentColumnAttrs: { cols: '12' },
    },
  },
}
