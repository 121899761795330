var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"white--text",attrs:{"clipped-right":_vm.$vuetify.breakpoint.lgAndUp,"app":"","flat":"","color":"primary"}},[_c('img',{staticClass:"mr-3",attrs:{"src":_vm.logoUrl,"alt":"App logo","height":"48px"}}),_c('router-link',{staticClass:"v-toolbar__title",staticStyle:{"cursor":"pointer"},attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('div',{on:{"click":navigate}},[_vm._v(_vm._s(_vm.appName))])]}}])}),_c('v-spacer'),(_vm.isAuthenticated)?_c('div',[(_vm.$vuetify.breakpoint.mdAndUp)?_c('NotificationsTopnavPopupPanel',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"color":"error","content":_vm.shortenedUnreadNotificationCount,"overlap":"","offset-y":"7","value":_vm.hasAnyNotifications}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-bell-outline")])],1)],1)]}}],null,false,4223225366)}):_c('v-btn',{attrs:{"icon":"","to":{ name: 'notifications' }}},[_c('v-badge',{attrs:{"color":"error","content":_vm.shortenedUnreadNotificationCount,"overlap":"","offset-y":"7","value":_vm.hasAnyNotifications}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-bell-outline")])],1)],1)],1):_vm._e(),(_vm.isAuthenticated)?_c('v-menu',{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-badge',{attrs:{"value":_vm.showRealtimeDisconnectedIndicator,"bottom":"","color":"warning","dot":"","offset-x":"6","offset-y":"6"}},[_c('v-avatar',{attrs:{"size":40}},[_c('v-img',{attrs:{"src":_vm.profilePicUrl,"alt":"Profile pic"}})],1)],1)],1)]}}],null,false,305407016)},[_c('v-list',[_vm._l((_vm.routes),function(route,i){return [(route.name)?_c('v-list-item',{key:route.name,attrs:{"to":route,"exact":""}},[_c('v-list-item-content',[_c('v-badge',{attrs:{"bottom":"","dot":"","value":_vm.showRealtimeDisconnectedIndicator && route.meta && route.meta.showRealtimeIndicator === true,"color":"warning","offset-x":"10","offset-y":"15"}},[_c('v-list-item-title',{staticClass:"route-title"},[_vm._v(" "+_vm._s(route.meta && route.meta.title ? route.meta.title : route.name)+" ")])],1)],1)],1):(route.isDivider)?_c('v-divider',{key:("divider-" + i)}):_c('v-list-item',{key:("unknown-" + i)},[_vm._v("Unknown")])]}),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){$event.preventDefault();return _vm.$emit('logout')}}},[_c('v-list-item-title',[_vm._v("Sign Out")])],1)],2)],1):_c('v-skeleton-loader',{attrs:{"type":"avatar","boilerplate":!_vm.authBusyLoading || _vm.authHasError}}),_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.$emit('toggle-sidenav')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }