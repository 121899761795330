<script>
import { reactiveFormatRelativeTimeAgo } from '@/infrastructure/dates/dateFormatting'
import { formatNotificationTemplateString } from '../services/notificationFormatting'
import store from '@/infrastructure/store'
import { toRefs } from '@vue/composition-api'
import NotificationActionButton from '@/features/notifications/components/NotificationActionButton'
import config from '@/config'

export default {
  components: { NotificationActionButton },
  props: {
    notificationId: { type: String, required: true },
    type: { type: String, required: true },
    typeId: { type: String },
    recipientDependentId: { type: String },
    recipientDependentName: { type: String },
    defaultTitleTemplate: { type: String, required: true },
    defaultBodyTemplate: { type: String },
    templateValues: { type: String },
    extraData: { type: String },
    created: { type: String },
    readOn: { type: String },
  },

  setup(props) {
    const createdRelativeTimeAgo = reactiveFormatRelativeTimeAgo(toRefs(props).created)

    return {
      createdRelativeTimeAgo,
    }
  },

  data() {
    return {
      formatNotificationTemplateString,

      isMarkingRead: false,
      imageWidth: 30,
      hadThumbnailLoadError: false,
    }
  },

  computed: {
    parsedExtraData() {
      try {
        return JSON.parse(this.extraData)
      } catch {
        return undefined
      }
    },

    friendUserIdFromExtraData() {
      return this.extractPropertyFromExtraData('FriendUserId')
    },

    birthdayUserIdFromExtraData() {
      return this.extractPropertyFromExtraData('BirthdayUserId')
    },

    itemOwnerIdFromExtraData() {
      return this.extractPropertyFromExtraData('ItemOwnerId')
    },

    secretSantaEventIdFromExtraData() {
      return this.extractPropertyFromExtraData('SecretSantaEventId')
    },

    receiverUserNameFromExtraData() {
      return this.extractPropertyFromExtraData('ReceiverUserName')
    },

    isFriendItemType() {
      return (
        this.type === 'ItemContributed' ||
        this.type === 'ItemAdded' ||
        this.type === 'ItemContributionRemoved' ||
        this.type === 'ItemClaimed' ||
        this.type === 'ItemUnclaimed' ||
        this.type === 'ItemArchived' ||
        this.type === 'ItemUnarchived'
      )
    },

    isSecretSantaEventType() {
      return this.type === 'SecretSantaEvents'
    },

    thumbnailFullUrl() {
      if (this.type === 'NewFriendRequest' || this.type === 'FriendRequestAccepted') {
        if (this.friendUserIdFromExtraData) {
          return `${config.ApiBaseUrl}/UserProfilePics/${this.friendUserIdFromExtraData}`
        }
      }

      if (this.type === 'FriendBirthday') {
        if (this.birthdayUserIdFromExtraData) {
          return `${config.ApiBaseUrl}/UserProfilePics/${this.birthdayUserIdFromExtraData}`
        }
      }

      if (this.isFriendItemType) return `${config.ApiBaseUrl}/FriendItems/items/${this.typeId}/main-image`

      return undefined
    },

    avatarBadgeIcon() {
      if (this.type === 'NewFriendRequest') return 'mdi-account'
      if (this.type === 'FriendRequestAccepted') return 'mdi-account'

      if (this.type === 'ItemAdded') return 'mdi-plus'

      if (this.type === 'ItemContributed' || this.type === 'ItemContributionRemoved') return 'mdi-handshake-outline'

      if (this.type === 'ItemClaimed') return 'mdi-gavel'
      if (this.type === 'ItemUnclaimed') return 'mdi-lock-open-variant-outline'

      if (this.type === 'ItemArchived') return 'mdi-archive-arrow-down-outline'
      if (this.type === 'ItemUnarchived') return 'mdi-undo'

      if (this.isFriendItemType) return `mdi-format-list-bulleted-square`
      if (this.isSecretSantaEventType) return `mdi-gift-outline`

      return undefined
    },

    actionToViewUserFeedbackPage() {
      return {
        name: 'View user feedback',
        attributes: { color: 'primary', outlined: true },
        routeTo: { name: 'feedback' },
      }
    },

    actionToViewAdminUserFeedbackPage() {
      return {
        name: 'View admin user feedback',
        attributes: { color: 'primary', outlined: true },
        routeTo: { name: 'admin-feedback' },
      }
    },

    actionToViewFriendPage() {
      if (!this.friendUserIdFromExtraData) return undefined
      return {
        name: 'View their items',
        attributes: { color: 'primary', outlined: true },
        routeTo: { name: 'friend-with-items', params: { userId: this.friendUserIdFromExtraData } },
      }
    },

    actionToViewOwnBirthdayUserPage() {
      return {
        name: 'View my items',
        attributes: { color: 'primary', outlined: true },
        routeTo: { name: 'my-items' },
      }
    },

    actionToViewFriendBirthdayUserPage() {
      if (!this.birthdayUserIdFromExtraData) return undefined
      return {
        name: 'View their items',
        attributes: { color: 'primary', outlined: true },
        routeTo: { name: 'friend-with-items', params: { userId: this.birthdayUserIdFromExtraData } },
      }
    },

    actionToViewItemsOfItemOwner() {
      if (!this.itemOwnerIdFromExtraData) return undefined
      return {
        name: 'View their items',
        attributes: { color: 'primary', outlined: true },
        routeTo: {
          name: 'friend-with-items',
          params: { userId: this.itemOwnerIdFromExtraData },
          query: { highlightedItemId: this.typeId },
        },
      }
    },

    actionToViewArchivedItemsOfItemOwner() {
      if (!this.itemOwnerIdFromExtraData) return undefined
      return {
        name: 'View their archived items',
        attributes: { color: 'primary', outlined: true },
        routeTo: {
          name: 'friend-with-archived-items',
          params: { userId: this.itemOwnerIdFromExtraData },
          query: { highlightedItemId: this.typeId },
        },
      }
    },

    actionsForNewFriendRequest() {
      if (this.readOn) return []

      const userToImpersonateId = this.recipientDependentId
      return [
        {
          name: 'Reject',
          attributes: { color: 'error', text: true },
          func: async () => {
            await store.dispatch('friends/rejectFriendRequest', {
              friendUserOrRequestId: this.typeId,
              isRequestId: true,
              userToImpersonateId: userToImpersonateId,
            })
            await this.markRead()
          },
          icon: 'mdi-account-cancel-outline',
        },
        {
          name: 'Accept',
          attributes: { color: 'success', outlined: true },
          func: async () => {
            await store.dispatch('friends/acceptFriendRequest', {
              friendUserOrRequestId: this.typeId,
              isRequestId: true,
              userToImpersonateId: userToImpersonateId,
            })
            await this.markRead()
          },
          icon: 'mdi-account-check-outline',
        },
      ]
    },

    actionToViewSecretSantaEvent() {
      if (!this.secretSantaEventIdFromExtraData) return undefined
      return {
        name: 'View event',
        attributes: { color: 'primary', outlined: true },
        routeTo: { name: 'secret-santa-event', params: { eventId: this.secretSantaEventIdFromExtraData } },
      }
    },

    mainCardAction() {
      if (this.type === 'UserFeedbackReplyFromAdmin') return this.actionToViewUserFeedbackPage
      if (this.type === 'UserFeedbackAdded') return this.actionToViewAdminUserFeedbackPage
      if (this.type === 'FriendRequestAccepted') return this.actionToViewFriendPage
      if (this.type === 'OwnBirthday') return this.actionToViewOwnBirthdayUserPage
      if (this.type === 'FriendBirthday') return this.actionToViewFriendBirthdayUserPage
      if (this.isFriendItemType) {
        return this.type !== 'ItemArchived' ? this.actionToViewItemsOfItemOwner : this.actionToViewArchivedItemsOfItemOwner
      }
      if (this.isSecretSantaEventType) return this.actionToViewSecretSantaEvent

      return undefined
    },

    actionButtons() {
      switch (this.type) {
        case 'NewFriendRequest':
          return this.actionsForNewFriendRequest
      }

      return []
    },

    actions() {
      const actions = []

      actions.push(...this.actionButtons)

      return actions
    },

    chips() {
      const chips = []

      if (this.recipientDependentName) {
        chips.push({ text: this.recipientDependentName, icon: 'mdi-human-male-child' })
      }

      if (this.type === 'SecretSantaEvents') {
        chips.push({ text: this.receiverUserNameFromExtraData || 'Secret', icon: 'mdi-gift-outline', color: 'success' })
      }

      return chips
    },

    cardListeners() {
      return this.mainCardAction ? { click: () => this.executeMainAction() } : null
    },
  },

  methods: {
    extractPropertyFromExtraData(propertyName) {
      const parsedExtraData = this.parsedExtraData

      if (!parsedExtraData) {
        console.warn('parsedExtraData is undefined')
        return undefined
      }

      const propertyValue = parsedExtraData[propertyName]
      if (!propertyValue) {
        console.warn(`${propertyName} (of parsedExtraData) is undefined`)
        return undefined
      }

      return propertyValue
    },

    executeMainAction() {
      if (!this.readOn) {
        this.markRead()
      }

      if (this.mainCardAction.func) {
        return this.mainCardAction.func()
      } else if (this.mainCardAction.routeTo) {
        this.$router.push(this.mainCardAction.routeTo)
      }
    },

    async markRead() {
      this.isMarkingRead = true
      try {
        await store.dispatch('notifications/markNotificationRead', { notificationId: this.notificationId })
      } finally {
        this.isMarkingRead = false
      }
    },
  },
}
</script>

<template>
  <v-card v-on="cardListeners">
    <v-card-text>
      <v-row no-gutters align="start">
        <v-col>
          <div>
            {{ createdRelativeTimeAgo }}
          </div>

          <div :class="[readOn ? 'secondary--text' : 'primary--text']">
            <div>{{ formatNotificationTemplateString(defaultTitleTemplate, templateValues) }}</div>
            <small v-if="defaultBodyTemplate">
              <pre style="white-space: pre-wrap" v-text="formatNotificationTemplateString(defaultBodyTemplate, templateValues)" />
            </small>
          </div>
        </v-col>

        <v-col v-if="!readOn" cols="auto">
          <v-btn fab x-small depressed color="transparent" :disabled="isMarkingRead" :loading="isMarkingRead" @click.stop="markRead">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <template v-if="avatarBadgeIcon || thumbnailFullUrl || chips.length > 0">
        <div class="pt-3"></div>

        <v-row no-gutters align="center">
          <v-badge :value="!!avatarBadgeIcon" color="primary" overlap bottom>
            <template v-slot:badge>
              <v-icon>{{ avatarBadgeIcon }}</v-icon>
            </template>

            <v-avatar :size="imageWidth">
              <v-img
                v-if="thumbnailFullUrl && !hadThumbnailLoadError"
                :src="thumbnailFullUrl"
                :width="imageWidth"
                :height="imageWidth"
                @error="hadThumbnailLoadError = true"
                @load="hadThumbnailLoadError = false"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate size="15" width="1" color="grey" />
                  </v-row>
                </template>
              </v-img>
              <v-icon v-else color="grey lighten-2">mdi-image-off-outline</v-icon>
            </v-avatar>
          </v-badge>

          <v-spacer />

          <v-chip-group column>
            <v-chip v-for="chip in chips" :key="chip.text" label :color="chip.color">
              <v-icon v-if="chip.icon" left>{{ chip.icon }}</v-icon>
              {{ chip.text }}
            </v-chip>
          </v-chip-group>
        </v-row>
      </template>
    </v-card-text>

    <div v-if="actions.length > 0">
      <v-card-actions>
        <v-spacer />

        <template v-for="action in actions">
          <NotificationActionButton v-if="action.func" :key="action.name" :action="action.func" v-bind="action.attributes" @click.stop>
            <v-icon v-if="action.icon" left>{{ action.icon }}</v-icon>
            {{ action.name }}
          </NotificationActionButton>

          <v-btn v-else-if="action.routeTo" :key="`${action.name}-2`" :to="action.routeTo" v-bind="action.attributes" @click.stop>
            <v-icon v-if="action.icon" left>{{ action.icon }}</v-icon>
            {{ action.name }}
          </v-btn>
        </template>
      </v-card-actions>
    </div>
  </v-card>
</template>
