<script>
import useAsyncLoading from '@/infrastructure/apis/asyncLoadingComposable'
import store from '@/infrastructure/store'
import { onMounted } from '@vue/composition-api'
import { formatDate } from '@/infrastructure/dates/dateFormatting'

export default {
  setup() {
    const { isBusy: isLoading, execute: loadUpcomingBirthdays } = useAsyncLoading({
      actionFactory: () => store.dispatch('friends/fetchUpcomingBirthdays'),
    })

    onMounted(loadUpcomingBirthdays)

    return {
      isLoading,
      formatDate,
    }
  },

  computed: {
    upcomingBirthdays: () => store.state.friends.upcomingBirthdays,
  },
}
</script>

<template>
  <v-card :loading="isLoading && upcomingBirthdays.length > 0">
    <v-card-title>
      <div class="text-center mx-auto">
        <div>Upcoming birthdays</div>
      </div>
    </v-card-title>

    <div class="mx-auto" style="width: 250px">
      <v-list rounded>
        <div v-if="isLoading && upcomingBirthdays.length === 0">
          <v-skeleton-loader type="list-item" />
          <v-skeleton-loader type="list-item" />
        </div>
        <v-list-item v-else-if="upcomingBirthdays.length === 0">
          <v-list-item-content class="text-center grey--text">
            <v-list-item-title>No upcoming birthdays</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-for="upcomingBirthday in upcomingBirthdays"
          :key="upcomingBirthday.userId"
          :to="{ name: 'friend-with-items', params: { userId: upcomingBirthday.userId } }"
        >
          <v-list-item-avatar size="24">
            <v-img v-if="upcomingBirthday.picture" :alt="`${upcomingBirthday.fullName} avatar`" :src="upcomingBirthday.picture"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ upcomingBirthday.fullName }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-list-item-action-text>{{ formatDate(upcomingBirthday.nextBirthday, 'D MMM') }}</v-list-item-action-text>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </div>
  </v-card>
</template>
