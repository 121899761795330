<script>
export default {
  props: {
    message: { type: String, required: true },
  },

  computed: {
    htmlMessage() {
      return this.message.replace(/\n/g, '<br>')
    },
  },

  data() {
    return {
      isOpen: true,
    }
  },

  watch: {
    isOpen(to) {
      if (!to) {
        this.$emit('close')
      }
    },
  },
}
</script>

<template>
  <v-snackbar app :timeout="10000" v-model="isOpen" color="error">
    <div v-html="htmlMessage"></div>

    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="isOpen = false"> Close</v-btn>
    </template>
  </v-snackbar>
</template>
