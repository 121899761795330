<script>
import store from '@/infrastructure/store'
import GlobalErrorSnackbar from '@/infrastructure/errors/components/GlobalErrorSnackbar'

export default {
  components: {
    GlobalErrorSnackbar,
  },

  computed: {
    errors: () => store.state.errors,
  },

  methods: {
    removeError(error) {
      store.commit('removeError', error)
    },
  },
}
</script>

<template>
  <div>
    <GlobalErrorSnackbar v-for="error in errors" :key="error.id" :message="error.message" @close="removeError(error)" />
  </div>
</template>
