export function filterVisibleRoutes({ routes, authUserRoles, authUserIsImpersonating, navGroup, bottomNav }) {
  return routes.filter((route) => {
    if (route.meta && route.meta.navHide) {
      return false
    }

    if (route.meta && route.meta.requiresRole) {
      if (!authUserRoles.includes(route.meta.requiresRole)) {
        return false
      }
    }

    if (authUserIsImpersonating && route.meta && route.meta.hideWhenImpersonating) {
      return false
    }

    const desiredGroup = navGroup || 'default'
    const routeGroup = route.meta && route.meta.navGroup ? route.meta.navGroup : 'default'
    if (desiredGroup !== routeGroup) {
      return false
    }

    if (bottomNav && (!route.meta || !route.meta.bottomNav)) {
      return false
    }

    // default to true
    return true
  })
}
