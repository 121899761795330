import { createApiInstance } from '@/infrastructure/apis/apiInstance'
import Vue from 'vue'

export default {
  namespaced: true,
  state: () => ({
    userDisplaySummariesById: {},
  }),
  getters: {
    getUserDisplaySummaryById: (state) => (userId) => state.userDisplaySummariesById[userId],
  },
  mutations: {
    replaceOrAddUserDisplaySummaries(state, { users }) {
      users.forEach((user) => {
        Vue.set(state.userDisplaySummariesById, user.userId, user)
      })
    },
  },
  actions: {
    async fetchUserDisplaySummaries(context, { userIds, skipLocalCache }) {
      if (!skipLocalCache) {
        const summaries = userIds.map((userId) => context.state.userDisplaySummariesById[userId])

        //check to ensure all users are non-null
        if (summaries.every((summary) => !!summary)) {
          return summaries
        }
      }

      const queryParams = userIds.map((id) => `userIds[]=${id}`)
      const response = await createApiInstance().get(`/UserDisplaySummaries?${queryParams.join('&')}`)
      context.commit('replaceOrAddUserDisplaySummaries', { users: response.data })
    },
  },
}
