<script>
import store from '@/infrastructure/store'
import UserNotice from '@/features/home/components/UserNotice'

export default {
  components: { UserNotice },

  computed: {
    nonDismissedNotices: () => store.state.userNotices.nonDismissedNotices,
  },

  mounted() {
    store.dispatch('userNotices/loadNonDismissedNotices')
  },

  methods: {
    onNoticeDismissed(notice) {
      store.dispatch('userNotices/dismissNotice', { notice: notice })
    },
  },
}
</script>

<template>
  <div>
    <v-slide-x-transition group>
      <UserNotice
        v-for="notice of nonDismissedNotices"
        :key="notice.noticeDate"
        :notice-date="notice.noticeDate"
        :type="notice.type"
        class="mb-3"
        @dismissed="onNoticeDismissed(notice)"
      />
    </v-slide-x-transition>
  </div>
</template>
