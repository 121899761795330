import { createApiInstance } from '@/infrastructure/apis/apiInstance'

export default {
  namespaced: true,
  state: () => ({
    myItemsRepository: {},

    myUnarchivedItemIds: [],
    myArchivedItemIds: [],

    friendItemsRepository: {},

    friendUnarchivedItemIdsByUserId: {},
    friendArchivedItemIdsByUserId: {},
    friendClaimedItemIdsByUserId: {},
    friendSecretSantaSuggestedItemIdsByUserId: {},

    reasonsToAddFriendItemInSecretInsteadByUserId: {},
  }),
  getters: {
    myUnarchivedItems: (state) => state.myUnarchivedItemIds.map((id) => state.myItemsRepository[id]),
    myArchivedItems: (state) => state.myArchivedItemIds.map((id) => state.myItemsRepository[id]),

    getFriendUnarchivedItems: (state) => (userId) => (state.friendUnarchivedItemIdsByUserId[userId] || []).map((id) => state.friendItemsRepository[id]),
    getFriendArchivedItems: (state) => (userId) => (state.friendArchivedItemIdsByUserId[userId] || []).map((id) => state.friendItemsRepository[id]),
    getFriendClaimedItems: (state) => (userId) => (state.friendClaimedItemIdsByUserId[userId] || []).map((id) => state.friendItemsRepository[id]),

    getFriendSecretSantaSuggestedItems: (state) => (userId) =>
      (state.friendSecretSantaSuggestedItemIdsByUserId[userId] || []).map((id) => state.friendItemsRepository[id]),
  },
  mutations: {
    setMyUnarchivedItems(state, items) {
      items.forEach((item) => (state.myItemsRepository[item.id] = item))
      state.myUnarchivedItemIds = items.map((item) => item.id)
    },
    setMyArchivedItems(state, items) {
      items.forEach((item) => (state.myItemsRepository[item.id] = item))
      state.myArchivedItemIds = items.map((item) => item.id)
    },
    replaceMyItem(state, { item }) {
      state.myItemsRepository = { ...state.myItemsRepository, [item.id]: item }
    },

    setFriendItems(state, { userId, items }) {
      items.forEach((item) => (state.friendItemsRepository[item.id] = item))
      state.friendUnarchivedItemIdsByUserId = {
        ...state.friendUnarchivedItemIdsByUserId,
        [userId]: items.map((item) => item.id),
      }
    },
    setFriendArchivedItems(state, { userId, items }) {
      items.forEach((item) => (state.friendItemsRepository[item.id] = item))
      state.friendArchivedItemIdsByUserId = {
        ...state.friendArchivedItemIdsByUserId,
        [userId]: items.map((item) => item.id),
      }
    },
    setFriendClaimedItems(state, { userId, items }) {
      items.forEach((item) => (state.friendItemsRepository[item.id] = item))
      state.friendClaimedItemIdsByUserId = {
        ...state.friendClaimedItemIdsByUserId,
        [userId]: items.map((item) => item.id),
      }
    },
    setFriendSecretSantaSuggestedItems(state, { userId, items }) {
      items.forEach((item) => (state.friendItemsRepository[item.id] = item))
      state.friendSecretSantaSuggestedItemIdsByUserId = {
        ...state.friendSecretSantaSuggestedItemIdsByUserId,
        [userId]: items.map((item) => item.id),
      }
    },
    replaceFriendItem(state, { item }) {
      state.friendItemsRepository = { ...state.friendItemsRepository, [item.id]: item }
    },

    setReasonsToAddFriendItemInSecretInstead(state, { friendUserId, reasons }) {
      state.reasonsToAddFriendItemInSecretInsteadByUserId = {
        ...state.reasonsToAddFriendItemInSecretInsteadByUserId,
        [friendUserId]: reasons,
      }
    },
  },
  actions: {
    async fetchMyItems(context) {
      const response = await createApiInstance().get('/MyItems')
      context.commit('setMyUnarchivedItems', response.data)
    },
    async fetchMyArchivedItems(context) {
      const response = await createApiInstance().get('/MyItems?filter=Archived')
      context.commit('setMyArchivedItems', response.data)
    },
    async fetchMyItem(context, { itemId }) {
      const response = await createApiInstance().get(`/MyItems/${itemId}`)
      context.commit('replaceMyItem', { item: response.data })
    },
    async addMyItem(context, { item }) {
      const response = await createApiInstance().post('/MyItems', item)
      await context.dispatch('fetchMyItems')
      return response.data
    },
    async updateMyItemRating(context, { itemId, newRating }) {
      await createApiInstance().put(`/MyItems/${itemId}`, { rating: newRating || null })
      await context.dispatch('fetchMyItem', { itemId: itemId })
    },
    async updateMyItemName(context, { itemId, newName }) {
      await createApiInstance().put(`/MyItems/${itemId}`, { name: newName || null })
      await context.dispatch('fetchMyItem', { itemId: itemId })
    },
    async updateMyItemPrice(context, { itemId, newPrice }) {
      await createApiInstance().put(`/MyItems/${itemId}`, { price: newPrice || null })
      await context.dispatch('fetchMyItem', { itemId: itemId })
    },
    async updateMyItemShop(context, { itemId, newShop }) {
      await createApiInstance().put(`/MyItems/${itemId}`, { shop: newShop || null, setShopEmpty: !newShop })
      await context.dispatch('fetchMyItem', { itemId: itemId })
    },
    async updateMyItemDescription(context, { itemId, newDescription }) {
      await createApiInstance().put(`/MyItems/${itemId}`, {
        description: newDescription || null,
        setDescriptionEmpty: !newDescription,
      })
      await context.dispatch('fetchMyItem', { itemId: itemId })
    },
    async updateMyItemLinks(context, { itemId, newLinks }) {
      await createApiInstance().put(`/MyItems/${itemId}`, { links: newLinks || null })
      await context.dispatch('fetchMyItem', { itemId: itemId })
    },
    async replaceMainImageOfMyItem(context, { itemId, newImageId }) {
      await createApiInstance().put(`/MyItems/${itemId}/main-image/${newImageId}`)
      await context.dispatch('fetchMyItem', { itemId: itemId })
    },
    async deleteMainImageOfMyItem(context, { itemId }) {
      await createApiInstance().delete(`/MyItems/${itemId}/main-image`)
      await context.dispatch('fetchMyItem', { itemId: itemId })
    },
    async archiveMyItem(context, { itemId }) {
      await createApiInstance().delete(`/MyItems/${itemId}`)
      await context.dispatch('fetchMyItem', { itemId: itemId })
    },
    async unarchiveMyItem(context, { itemId }) {
      await createApiInstance().put(`/MyItems/${itemId}/unarchive`)
      await context.dispatch('fetchMyItem', { itemId: itemId })
    },

    async fetchFriendItems(context, { userId }) {
      const response = await createApiInstance().get(`/FriendItems/${userId}`)
      context.commit('setFriendItems', { userId, items: response.data })
    },
    async fetchFriendArchivedItems(context, { userId }) {
      const response = await createApiInstance().get(`/FriendItems/${userId}?filter=Archived`)
      context.commit('setFriendArchivedItems', { userId, items: response.data })
    },
    async fetchFriendClaimedItems(context, { userId }) {
      const response = await createApiInstance().get(`/FriendItems/${userId}?filter=Claimed`)
      context.commit('setFriendClaimedItems', { userId, items: response.data })
    },
    async fetchFriendSecretSantaSuggestedItems(context, { userId, budgetAmount }) {
      const budgetAmountParam = budgetAmount === null || budgetAmount === undefined ? '' : budgetAmount
      const response = await createApiInstance().get(`/FriendItems/${userId}?filter=SecretSantaShortlist&secretSantaBudgetAmount=${budgetAmountParam}`)
      context.commit('setFriendSecretSantaSuggestedItems', { userId, items: response.data })
    },
    async fetchFriendItem(context, { itemId }) {
      const response = await createApiInstance().get(`/FriendItems/items/${itemId}`)
      context.commit('replaceFriendItem', { item: response.data })
    },
    async addFriendItem(context, { userId, item, addSecretly }) {
      const response = await createApiInstance().post(`/FriendItems/${userId}?addSecretly=${addSecretly ? 'true' : 'false'}`, item)
      await context.dispatch('fetchFriendItems', { userId: userId })
      return response.data
    },
    async updateFriendItemName(context, { itemId, newName }) {
      await createApiInstance().put(`/FriendItems/items/${itemId}`, { name: newName || null })
      await context.dispatch('fetchFriendItem', { itemId: itemId })
    },
    async updateFriendItemPrice(context, { itemId, newPrice }) {
      await createApiInstance().put(`/FriendItems/items/${itemId}`, { price: newPrice || null })
      await context.dispatch('fetchFriendItem', { itemId: itemId })
    },
    async updateFriendItemRating(context, { itemId, newRating }) {
      await createApiInstance().put(`/FriendItems/items/${itemId}`, { rating: newRating || null })
      await context.dispatch('fetchFriendItem', { itemId: itemId })
    },
    async updateFriendItemShop(context, { itemId, newShop }) {
      await createApiInstance().put(`/FriendItems/items/${itemId}`, { shop: newShop || null, setShopEmpty: !newShop })
      await context.dispatch('fetchFriendItem', { itemId: itemId })
    },
    async updateFriendItemDescription(context, { itemId, newDescription }) {
      await createApiInstance().put(`/FriendItems/items/${itemId}`, {
        description: newDescription || null,
        setDescriptionEmpty: !newDescription,
      })
      await context.dispatch('fetchFriendItem', { itemId: itemId })
    },
    async updateFriendItemLinks(context, { itemId, newLinks }) {
      await createApiInstance().put(`/FriendItems/items/${itemId}`, { links: newLinks || null })
      await context.dispatch('fetchFriendItem', { itemId: itemId })
    },
    async replaceMainImageOfFriendSurpriseItem(context, { itemId, newImageId }) {
      await createApiInstance().put(`/FriendItems/items/${itemId}/main-image/${newImageId}`)
      await context.dispatch('fetchFriendItem', { itemId: itemId })
    },
    async deleteMainImageOfFriendSurpriseItem(context, { itemId }) {
      await createApiInstance().delete(`/FriendItems/items/${itemId}/main-image`)
      await context.dispatch('fetchFriendItem', { itemId: itemId })
    },
    async claimFriendItem(context, { itemId, claimSecretly }) {
      await createApiInstance().put(`/FriendItems/items/${itemId}/claim?secret=${claimSecretly ? 'true' : 'false'}`)
      await context.dispatch('fetchFriendItem', { itemId: itemId })
    },
    async unclaimFriendItem(context, { itemId }) {
      await createApiInstance().delete(`/FriendItems/items/${itemId}/claim`)
      await context.dispatch('fetchFriendItem', { itemId: itemId })
    },
    async contributeOnFriendItem(context, { itemId, amount }) {
      await createApiInstance().put(`/FriendItems/items/${itemId}/contribution/${amount || 0}`)
      await context.dispatch('fetchFriendItem', { itemId: itemId })
    },
    async archiveFriendItem(context, { itemId }) {
      await createApiInstance().delete(`/FriendItems/items/${itemId}`)
      await context.dispatch('fetchFriendItem', { itemId: itemId })
    },
    async unarchiveFriendItem(context, { itemId }) {
      await createApiInstance().put(`/FriendItems/items/${itemId}/unarchive`)
      await context.dispatch('fetchFriendItem', { itemId: itemId })
    },

    async autoDetectItemDetailsFromShopUrl(context, { shopUrl }) {
      const response = await createApiInstance().get(`/Shops/auto-detect-item-details?shopUrl=${encodeURIComponent(shopUrl)}`)
      return response.data
    },

    async fetchReasonsToAddFriendItemInSecretInstead(context, { friendUserId }) {
      const response = await createApiInstance().get(`/FriendItems/${friendUserId}/reasons-to-add-item-in-secret-instead`)
      context.commit('setReasonsToAddFriendItemInSecretInstead', { friendUserId: friendUserId, reasons: response.data })
    },
  },
}
