<script>
const startTime = new Date().getTime()

export default {
  data() {
    return {
      showingManualUpdateSnack: false,
      waitDurationForAutoUpdate: 5000,
      remainingDurationToAutoUpdate: false,
      isStartingToRefreshApp: false,
      registration: null,
      broadcast: new BroadcastChannel('update-channel'),
    }
  },

  computed: {
    showingAutoUpdateSnack() {
      return !!this.remainingDurationToAutoUpdate
    },
    hasRemainingAutoUpdateCountDown() {
      return this.remainingDurationToAutoUpdate > 500
    },
    autoUpdateCountDownPercentage() {
      return 100 * (this.remainingDurationToAutoUpdate / this.waitDurationForAutoUpdate)
    },
  },

  created() {
    document.addEventListener('swUpdated', this.onSwUpdated, { once: true })
    console.debug('UpdateManager created')
  },

  methods: {
    onSwUpdated(e) {
      this.registration = e.detail

      if (!this.registration) {
        console.error('onSwUpdated was triggered without the registration detail defined, ignoring the event and doing nothing')
        return
      }

      const numberMsSinceAppStartup = new Date().getTime() - startTime
      if (numberMsSinceAppStartup < 2000) {
        console.log('Sw updated fired shortly after startup, assuming it got installed on a previous app load so auto-applying update now')

        this.remainingDurationToAutoUpdate = this.waitDurationForAutoUpdate
        const decreaseTimeoutAmount = 1000
        const gracePeriodToAllowUiProgressBarToCatchUp = 500
        const handle = setInterval(() => {
          this.remainingDurationToAutoUpdate -= decreaseTimeoutAmount

          if (this.remainingDurationToAutoUpdate <= 0) {
            this.remainingDurationToAutoUpdate = 1
            console.debug('Timeout ran out for auto update')
            clearInterval(handle)

            setTimeout(() => {
              this.refreshApp()
            }, gracePeriodToAllowUiProgressBarToCatchUp)
          }
        }, decreaseTimeoutAmount)
      } else {
        this.showingManualUpdateSnack = true
      }
    },

    refreshApp() {
      this.isStartingToRefreshApp = true

      const broadcast = new BroadcastChannel('update-channel')
      broadcast.onmessage = (event) => {
        console.debug('UpdateManager received BroadcastChannel message', {
          event: JSON.stringify(event),
          eventData: JSON.stringify(event.data),
        })

        if (event.data && event.data.type === 'successfullyAppliedSkipWaiting') {
          console.info('UpdateManager received successfullyAppliedSkipWaiting message, performing reload')
          window.location.reload()
        }
      }

      if (this.registration && this.registration.waiting) {
        console.debug('Posting message skip-waiting')
        this.registration.waiting.postMessage('skip-waiting')
      }
    },
  },
}
</script>

<template>
  <v-snackbar v-if="showingManualUpdateSnack" app :value="true" :timeout="-1" bottom>
    <div v-if="!isStartingToRefreshApp" class="text-center">A new version is available</div>
    <div v-else class="text-center">Reloading app, please wait</div>
    <v-spacer />
    <div class="d-flex justify-center">
      <v-btn text color="success" :disabled="isStartingToRefreshApp" :loading="isStartingToRefreshApp" @click.native="refreshApp"> Apply now </v-btn>
    </div>
  </v-snackbar>
  <v-snackbar v-else-if="showingAutoUpdateSnack" app :value="true" :timeout="-1" bottom color="success" outlined>
    <div v-if="hasRemainingAutoUpdateCountDown" class="text-center">New version will be applied shortly</div>
    <div v-else class="d-flex align-center">
      <div class="mr-2">Reloading app, please wait</div>
      <v-progress-circular size="14" width="1" indeterminate />
    </div>
    <v-spacer />
    <div v-if="hasRemainingAutoUpdateCountDown" class="d-flex justify-center">
      <v-btn text @click.native="refreshApp"> Apply now</v-btn>
    </div>
    <v-progress-linear absolute bottom color="success" :value="autoUpdateCountDownPercentage" />
  </v-snackbar>
</template>
