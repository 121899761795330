<script>
import AddUserFeedbackCard from '@/infrastructure/userFeedbacks/components/AddUserFeedbackCard'

export default {
  components: { AddUserFeedbackCard },
}
</script>

<template>
  <AddUserFeedbackCard @feedback-added="$emit('action-complete')" />
</template>
