import { createApiInstance } from '@/infrastructure/apis/apiInstance'

export default {
  namespaced: true,
  state: () => ({
    pushNotificationSubscription: undefined,
  }),

  getters: {
    hasPushNotificationSubscription: (state) => !!state.pushNotificationSubscription,
  },

  mutations: {
    setPushNotificationSubscription(state, { subscription }) {
      state.pushNotificationSubscription = subscription
    },
  },

  actions: {
    async loadBirthDayAndMonth() {
      const response = await createApiInstance().get(`/UserProfile/birthday`)
      return response.data
    },
    async saveBirthDayAndMonth(context, { day, month }) {
      await createApiInstance().post(`/UserProfile/birthday?day=${day}&month=${month}`)
    },
  },
}
